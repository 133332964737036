import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import { HomePageServices } from '../../../../../services';

const UpdateHomePageAboutActionPopUp = ({ user, action, concludeUpdateActionPopUp }) => {
    
    const [primaryLable, setPrimaryLable] = useState(action != null ? action.primary.lable : "");
    const [hasPrimaryLableMessage, setHasPrimaryLableMessage] = useState(false);
    const [primaryLableMessage, setPrimaryLableMessage] = useState("");
    const [primaryLableMessageType, setPrimaryLableMessageType] = useState("");

    const [primaryAction, setPrimaryAction] = useState(action != null ? action.primary.action : "");
    const [hasPrimaryActionMessage, setHasPrimaryActionMessage] = useState(false);
    const [primaryActionMessage, setPrimaryActionMessage] = useState("");
    const [primaryActionMessageType, setPrimaryActionMessageType] = useState("");
    
    const [secondaryLable, setSecondaryLable] = useState(action != null ? action.secondary.lable : "");
    const [hasSecondaryLableMessage, setHasSecondaryLableMessage] = useState(false);
    const [secondaryLableMessage, setSecondaryLableMessage] = useState("");
    const [secondaryLableMessageType, setSecondaryLableMessageType] = useState("");

    const [secondaryValue, setSecondaryValue] = useState(action != null ? action.secondary.value : "");
    const [hasSecondaryValueMessage, setHasSecondaryValueMessage] = useState(false);
    const [secondaryValueMessage, setSecondaryValueMessage] = useState("");
    const [secondaryValueMessageType, setSecondaryValueMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateActionSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(primaryLable.trim() === ""){
            setHasPrimaryLableMessage(true);
            setPrimaryLableMessage("Invalid Primary Lable");
            setPrimaryLableMessageType("error");
        }
        else{
            setHasPrimaryLableMessage(false);
            setPrimaryLableMessage("");
            setPrimaryLableMessageType("");
        }

        if(primaryAction.trim() === ""){
            setHasPrimaryActionMessage(true);
            setPrimaryActionMessage("Invalid Primary Action");
            setPrimaryActionMessageType("error");
        }
        else{
            setHasPrimaryActionMessage(false);
            setPrimaryActionMessage("");
            setPrimaryActionMessageType("");
        }

        if(secondaryLable.trim() === ""){
            setHasSecondaryLableMessage(true);
            setSecondaryLableMessage("Invalid Secondary Action");
            setSecondaryLableMessageType("error");
        }
        else{
            setHasSecondaryLableMessage(false);
            setSecondaryLableMessage("");
            setSecondaryLableMessageType("");
        }

        if(secondaryValue.trim() === ""){
            setHasSecondaryValueMessage(true);
            setSecondaryValueMessage("Invalid Secondary Value");
            setSecondaryValueMessageType("error");
        }
        else{
            setHasSecondaryValueMessage(false);
            setSecondaryValueMessage("");
            setSecondaryValueMessageType("");
        }

        if(primaryLable.trim() === "" || primaryAction.trim() === "" || secondaryValue.trim() === "" || secondaryLable.trim() === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveAboutActionsReq = await HomePageServices.updateAboutActions({
                primary_lable: primaryLable,
                primary_action: primaryAction,
                secondary_lable: secondaryLable,
                secondary_value: secondaryValue,
                secondary_icon: selectedImages.length > 0 ? selectedImages[0] : action.secondary.icon.id,
                token: user.access_token
            });

            if(saveAboutActionsReq.status === false){
                ToastAlert.notifyError(saveAboutActionsReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveAboutActionsReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateActionPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateActionSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutActionPrimaryLable.hasLable}
                        lable={inputs.updateHomePageAboutActionPrimaryLable.lable}
                        isMandatory={inputs.updateHomePageAboutActionPrimaryLable.is_mandatory}
                        hasMessage={hasPrimaryLableMessage}
                        message={primaryLableMessage}
                        messageType={primaryLableMessageType}
                        isLimited={inputs.updateHomePageAboutActionPrimaryLable.is_limited}
                        limit={inputs.updateHomePageAboutActionPrimaryLable.max_characters}
                        value={primaryLable}
                    >
                        <input 
                            type={inputs.updateHomePageAboutActionPrimaryLable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutActionPrimaryLable.placeholder}
                            onChange={(e) => setPrimaryLable(e.target.value)}
                            value={primaryLable}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutActionPrimaryAction.hasLable}
                        lable={inputs.updateHomePageAboutActionPrimaryAction.lable}
                        isMandatory={inputs.updateHomePageAboutActionPrimaryAction.is_mandatory}
                        hasMessage={hasPrimaryActionMessage}
                        message={primaryActionMessage}
                        messageType={primaryActionMessageType}
                        isLimited={inputs.updateHomePageAboutActionPrimaryAction.is_limited}
                        limit={inputs.updateHomePageAboutActionPrimaryAction.max_characters}
                        value={primaryAction}
                    >
                        <input 
                            type={inputs.updateHomePageAboutActionPrimaryAction.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutActionPrimaryAction.placeholder}
                            onChange={(e) => setPrimaryAction(e.target.value)}
                            value={primaryAction}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutActionSecondaryLable.hasLable}
                        lable={inputs.updateHomePageAboutActionSecondaryLable.lable}
                        isMandatory={inputs.updateHomePageAboutActionSecondaryLable.is_mandatory}
                        hasMessage={hasSecondaryLableMessage}
                        message={secondaryLableMessage}
                        messageType={secondaryLableMessageType}
                        isLimited={inputs.updateHomePageAboutActionSecondaryLable.is_limited}
                        limit={inputs.updateHomePageAboutActionSecondaryLable.max_characters}
                        value={secondaryLable}
                    >
                        <input 
                            type={inputs.updateHomePageAboutActionSecondaryLable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutActionSecondaryLable.placeholder}
                            onChange={(e) => setSecondaryLable(e.target.value)}
                            value={secondaryLable}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutActionSecondaryValue.hasLable}
                        lable={inputs.updateHomePageAboutActionSecondaryValue.lable}
                        isMandatory={inputs.updateHomePageAboutActionSecondaryValue.is_mandatory}
                        hasMessage={hasSecondaryValueMessage}
                        message={secondaryValueMessage}
                        messageType={secondaryValueMessageType}
                        isLimited={inputs.updateHomePageAboutActionSecondaryValue.is_limited}
                        limit={inputs.updateHomePageAboutActionSecondaryValue.max_characters}
                        value={secondaryValue}
                    >
                        <input 
                            type={inputs.updateHomePageAboutActionSecondaryValue.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutActionSecondaryValue.placeholder}
                            onChange={(e) => setSecondaryValue(e.target.value)}
                            value={secondaryValue}
                        />
                    </TextInputRT>

                    <FileUploadInput 
                        data={imageData}
                        user={user} 
                        lable={imagePopUpLable} 
                        acceptedFileType={imageType} 
                        isPreview={action === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${action?.secondary?.icon?.image_url?.full?.high_res}`}
                        isMultiple={imageIsMultiple} 
                        maxSelection={imageMaxFileSelection} 
                        isError={imageIsError}
                        action={(e) => handleImageSelection(e)} 
                    />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateActionPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )

}

export default UpdateHomePageAboutActionPopUp