import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT';
import TextAreaInput from '../../../core/form/TextAreaInput';
import FileUploadInput from '../../../core/form/FileUploadInput';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { RequestAQuoteServices } from '../../../../services';

const UpdateRequestQuoteSectionHeadingsPopUp = ({ user, section_headings, concludeUpdateSectionHeadingsPopUp }) => {
    
    const [tagline, setTagline] = useState(section_headings != null ? section_headings.tagline : "");
    const [hasTaglineMessage, setHasTaglineMessage] = useState(false);
    const [taglineMessage, setTaglineMessage] = useState("");
    const [taglineMessageType, setTaglineMessageType] = useState("");

    const [heading, setHeading] = useState(section_headings != null ? section_headings.heading : "");
    const [hasHeadingMessage, setHasHeadingMessage] = useState(false);
    const [headingMessage, setHeadingMessage] = useState("");
    const [headingMessageType, setHeadingMessageType] = useState("");

    const [description, setDescription] = useState(section_headings != null ? section_headings.description : "");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateSectionHeadingsSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(tagline.trim() === ""){
            setHasTaglineMessage(true);
            setTaglineMessage("Invalid Tagline");
            setTaglineMessageType("error");
        }
        else{
            setHasTaglineMessage(false);
            setTaglineMessage("");
            setTaglineMessageType("");
        }

        if(heading.trim() === ""){
            setHasHeadingMessage(true);
            setHeadingMessage("Invalid Value");
            setHeadingMessageType("error");
        }
        else{
            setHasHeadingMessage(false);
            setHeadingMessage("");
            setHeadingMessageType("");
        }

        if(section_headings === null){

            if(tagline.trim() === "" || heading.trim() === ""){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const saveHeaderActionReq = await RequestAQuoteServices.saveSectionHeadings({
                    tagline: tagline,
                    heading: heading,
                    description: description,
                    token: user.access_token
                });
    
                if(saveHeaderActionReq.status === false){
                    ToastAlert.notifyError(saveHeaderActionReq.message);
                    setSubmitButtonLoading(false);
                }
                else{
                    ToastAlert.notifySuccess(saveHeaderActionReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonLable('Success');
                    concludeUpdateSectionHeadingsPopUp({
                        status: true
                    })
                }
            }
        }
        else{
            if(tagline.trim() === "" || heading.trim() === ""){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const saveHeaderActionReq = await RequestAQuoteServices.saveSectionHeadings({
                    tagline: tagline,
                    heading: heading,
                    description: description,
                    token: user.access_token
                });
    
                if(saveHeaderActionReq.status === false){
                    ToastAlert.notifyError(saveHeaderActionReq.message);
                    setSubmitButtonLoading(false);
                }
                else{
                    ToastAlert.notifySuccess(saveHeaderActionReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonLable('Success');
                    concludeUpdateSectionHeadingsPopUp({
                        status: true
                    })
                }
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateSectionHeadingsSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.whyChooseUsTagline.hasLable}
                        lable={inputs.whyChooseUsTagline.lable}
                        isMandatory={inputs.whyChooseUsTagline.is_mandatory}
                        hasMessage={hasTaglineMessage}
                        message={taglineMessage}
                        messageType={taglineMessageType}
                        isLimited={inputs.whyChooseUsTagline.is_limited}
                        limit={inputs.whyChooseUsTagline.max_characters}
                        value={tagline}
                    >
                        <input 
                            type={inputs.whyChooseUsTagline.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.whyChooseUsTagline.placeholder}
                            onChange={(e) => setTagline(e.target.value)}
                            value={tagline}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.whyChooseUsHeading.hasLable}
                        lable={inputs.whyChooseUsHeading.lable}
                        isMandatory={inputs.whyChooseUsHeading.is_mandatory}
                        hasMessage={hasHeadingMessage}
                        message={headingMessage}
                        messageType={headingMessageType}
                        isLimited={inputs.whyChooseUsHeading.is_limited}
                        limit={inputs.whyChooseUsHeading.max_characters}
                        value={heading}
                    >
                        <input 
                            type={inputs.whyChooseUsHeading.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.whyChooseUsHeading.placeholder}
                            onChange={(e) => setHeading(e.target.value)}
                            value={heading}
                        />
                    </TextInputRT>

                    <TextAreaInput 
                        hasLable={inputs.whyChooseUsDescription.hasLable}
                        lable={inputs.whyChooseUsDescription.lable}
                        isMandatory={false}
                        hasIcon={false}
                        hasMessage={hasDescriptionMessage}
                        message={descriptionMessage}
                        messageType={descriptionMessageType}
                    >
                        <textarea 
                            type="text" 
                            className="cmsFormStepInputTextArea" 
                            placeholder={"Enter Description"}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            defaultValue={description}
                        >
                        </textarea>
                    </TextAreaInput>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateSectionHeadingsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )

}

export default UpdateRequestQuoteSectionHeadingsPopUp