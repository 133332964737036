import axios from "axios";
import { statusCodes, defaults } from "../../../utils";

const config = (token) => {
    return (
        {
            headers: {
                'Authorization': token
            }
        }
    )
}

const saveSectionHeadings = async (payload) => {
    if(!payload.tagline){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Tagline is required.",
        });

        return response
    }

    if(!payload.heading){

        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Heading is required.",
        });

        return response
    }

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }

    const data = {
        tagline: payload.tagline.trim(),
        heading: payload.heading.trim(),
        description: payload.description.trim()
    }

    try{
        let headers = await config(payload.token);

        const updateSectionHeadingsReq = await axios.post(`${defaults.API_BASE_URL}request-a-quote/section-headings`, data, headers);
        if(updateSectionHeadingsReq.status === statusCodes.CREATED){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.CREATED,
                message: "Section headings successfully updated.",
                data: updateSectionHeadingsReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        if(error.response.status === statusCodes.UNAUTHORISED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.UNAUTHORISED,
                message: "Please provide your token.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.FORBIDDEN){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.FORBIDDEN,
                message: "User doesn't have required rights to process this request.",
            });
    
            return response
        }
        else if(error.response.status === statusCodes.VALIDATION_FAILED){
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.VALIDATION_FAILED,
                message: "Invalid Inputs",
            });
    
            return response
        }
        else{
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to expected technical error."
            });
    
            return response
        }
    }
}

const getAuthSectionHeadings = async (payload) => {

    if(!payload.token){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.VALIDATION_FAILED,
            message: "Token is required.",
        });

        return response
    }
    try{
        let headers = await config(payload.token);

        const getAuthSectionHeadingsReq = await axios.get(`${defaults.API_BASE_URL}request-a-quote/auth/section-headings`, headers);
        if(getAuthSectionHeadingsReq.status === statusCodes.SUCCESS){
            const response = defaults.ServiceResponse({
                status: true,
                status_code: statusCodes.SUCCESS,
                message: "Section headings successfully recieved.",
                data: getAuthSectionHeadingsReq?.data?.data
            });
    
            return response
        }
        else {
            const response = defaults.ServiceResponse({
                status: false,
                status_code: statusCodes.BAD_REQUEST,
                message: "Request failed due to unexpected technical error."
            });
    
            return response
        }
    }
    catch(error){
        const response = defaults.ServiceResponse({
            status: false,
            status_code: statusCodes.BAD_REQUEST,
            message: "Request failed due to expected technical error."
        });

        return response
    }
}

const RequestAQuoteServices = {
    saveSectionHeadings,
    getAuthSectionHeadings
}

export default RequestAQuoteServices