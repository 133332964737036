import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import PopUp from '../../../components/core/popup';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { FooterServices } from '../../../services';
import UpdateFooterSectionHeadingsPopUp from '../../../components/dashboard/popups/Footer/UpdateFooterSectionHeadingsPopUp';
import UpdateFooterContactDetailsPopUp from '../../../components/dashboard/popups/Footer/UpdateFooterContactDetailsPopUp';
import UpdateFooterCopyrightDetailsPopUp from '../../../components/dashboard/popups/Footer/UpdateFooterCopyrightDetailsPopUp';
import UpdateFooterSection1HeadingPopUp from '../../../components/dashboard/popups/Footer/UpdateFooterSection1HeadingPopUp';
import AddFooterSection1LinkItemPopUp from '../../../components/dashboard/popups/Footer/AddFooterSection1LinkItemPopUp';
import AddFooterSection2LinkItemPopUp from '../../../components/dashboard/popups/Footer/AddFooterSection2LinkItemPopUp';
import AddFooterSection3LinkItemPopUp from '../../../components/dashboard/popups/Footer/AddFooterSection3LinkItemPopUp';
import UpdateFooterSection2HeadingPopUp from '../../../components/dashboard/popups/Footer/UpdateFooterSection2HeadingPopUp';
import UpdateFooterSection3HeadingPopUp from '../../../components/dashboard/popups/Footer/UpdateFooterSection3HeadingPopUp';

const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

const SortableSection1LinkItem = sortableElement(({value, index}) => (
    <TableRow key={index}>
        <DragHandle />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.lable} />
        <TableRowItem size={1} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? "page" : "URL"} />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? value?.action?.goto_page?.path : value?.action?.goto_url } />
        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
        <TableRowItem size={2} isCenter={false} hasChildren={true}>
            <TableRowActions>
                <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => console.log(value)}>edit</TableButton>
                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(value.id)}>delete</TableButton>
            </TableRowActions>
        </TableRowItem>
    </TableRow>
));
  
const SortableSection1LinksContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

const SortableSection2LinkItem = sortableElement(({value, index}) => (
    <TableRow key={index}>
        <DragHandle />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.lable} />
        <TableRowItem size={1} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? "page" : "URL"} />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? value?.action?.goto_page?.path : value?.action?.goto_url } />
        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
        <TableRowItem size={2} isCenter={false} hasChildren={true}>
            <TableRowActions>
                <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => console.log(value)}>edit</TableButton>
                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(value.id)}>delete</TableButton>
            </TableRowActions>
        </TableRowItem>
    </TableRow>
));
  
const SortableSection2LinksContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

const SortableSection3LinkItem = sortableElement(({value, index}) => (
    <TableRow key={index}>
        <DragHandle />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.lable} />
        <TableRowItem size={1} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? "page" : "URL"} />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? value?.action?.goto_page?.path : value?.action?.goto_url } />
        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
        <TableRowItem size={2} isCenter={false} hasChildren={true}>
            <TableRowActions>
                <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => console.log(value)}>edit</TableButton>
                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(value.id)}>delete</TableButton>
            </TableRowActions>
        </TableRowItem>
    </TableRow>
));
  
const SortableSection3LinksContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

const FooterPage = ({ user }) => {
    const navigate = useNavigate();

    const [sectionHeadings, setSectionHeadings] = useState([]);

    const handleGetSectionHeadingsRequest = async () => {
        const getSectionHeadingsReq = await FooterServices.getAuthSectionHeadings({
            token: user.access_token
        });

        if(getSectionHeadingsReq.status === false){
            ToastAlert.notifyError(getSectionHeadingsReq.message);
            setSectionHeadings([]);
        }
        else{
            setSectionHeadings(getSectionHeadingsReq.data);
        }
    }

    useEffect(() => {
        handleGetSectionHeadingsRequest();
    }, []);

    const [showUpdateSectionHeadingsPopUp, setShowUpdateSectionHeadingsPopUp] = useState(false);

    const handleConcludeUpdateSectionHeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateSectionHeadingsPopUp(false);
            handleGetSectionHeadingsRequest();
        }
    }
    
    const [contactDetails, setContactDetails] = useState([]);

    const handleGetContactDetailsRequest = async () => {
        const getContactDetailsReq = await FooterServices.getAuthContactDetails({
            token: user.access_token
        });

        if(getContactDetailsReq.status === false){
            ToastAlert.notifyError(getContactDetailsReq.message);
            setContactDetails([]);
        }
        else{
            setContactDetails(getContactDetailsReq.data);
        }
    }

    useEffect(() => {
        handleGetContactDetailsRequest();
    }, []);

    const [showUpdateContactDetailsPopUp, setShowUpdateContactDetailsPopUp] = useState(false);

    const handleConcludeUpdateContactDetailsPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateContactDetailsPopUp(false);
        }
        else{
            setShowUpdateContactDetailsPopUp(false);
            handleGetContactDetailsRequest();
        }
    }

    const [copyrightDetails, setCopyrightDetails] = useState([]);

    const handleGetCopyrightDetailsRequest = async () => {
        const getCopyrightDetailsReq = await FooterServices.getAuthCopyrightDetails({
            token: user.access_token
        });

        if(getCopyrightDetailsReq.status === false){
            ToastAlert.notifyError(getCopyrightDetailsReq.message);
            setCopyrightDetails([]);
        }
        else{
            setCopyrightDetails(getCopyrightDetailsReq.data);
        }
    }

    useEffect(() => {
        handleGetCopyrightDetailsRequest();
    }, []);

    const [showUpdateCopyrightDetailsPopUp, setShowUpdateCopyrightDetailsPopUp] = useState(false);

    const handleConcludeUpdateCopyrightDetailsPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateCopyrightDetailsPopUp(false);
        }
        else{
            setShowUpdateCopyrightDetailsPopUp(false);
            handleGetCopyrightDetailsRequest();
        }
    }

    const [section1Details, setSection1Details] = useState([]);

    const handleGetSection1DetailsRequest = async () => {
        const getSection1DetailsReq = await FooterServices.getAuthSection1Details({
            token: user.access_token
        });

        if(getSection1DetailsReq.status === false){
            ToastAlert.notifyError(getSection1DetailsReq.message);
            setSection1Details([]);
        }
        else{
            setSection1Details(getSection1DetailsReq.data);
            console.log(getSection1DetailsReq.data);
        }
    }

    useEffect(() => {
        handleGetSection1DetailsRequest();
    }, []);

    const [showUpdateSection1HeadingPopUp, setShowUpdateSection1HeadingPopUp] = useState(false);

    const handleConcludeUpdateSection1HeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSection1HeadingPopUp(false);
        }
        else{
            setShowUpdateSection1HeadingPopUp(false);
            handleGetSection1DetailsRequest();
        }
    }

    const [showAddSection1LinkPopUp, setShowAddSection1LinkPopUp] = useState(false);

    const handleConcludeAddSection1LinkPopUp = async (e) => {
        if(e.status === false){
            setShowAddSection1LinkPopUp(false);
        }
        else{
            setShowAddSection1LinkPopUp(false);
            handleGetSection1DetailsRequest();
        }
    }

    const handleSaveSortedSection1LinksList = async (list) => {

        const sortSectionLinksReq = await FooterServices.sortSection1LinkItems({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortSectionLinksReq.status === false){
            ToastAlert.notifyError(sortSectionLinksReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortSectionLinksReq.message);
            handleGetSection1DetailsRequest();
        }
    }

    const handleSortSection1LinksEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(section1Details.links, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedSection1LinksList(entityIdWithIndex);

        let data = {
            heading: section1Details.heading,
            links: [...arrayMove]
        }

        setSection1Details(data);
    }

    const [section2Details, setSection2Details] = useState([]);

    const handleGetSection2DetailsRequest = async () => {
        const getSection2DetailsReq = await FooterServices.getAuthSection2Details({
            token: user.access_token
        });

        if(getSection2DetailsReq.status === false){
            ToastAlert.notifyError(getSection2DetailsReq.message);
            setSection2Details([]);
        }
        else{
            setSection2Details(getSection2DetailsReq.data);
            console.log(getSection2DetailsReq.data);
        }
    }

    useEffect(() => {
        handleGetSection2DetailsRequest();
    }, []);

    const [showUpdateSection2HeadingPopUp, setShowUpdateSection2HeadingPopUp] = useState(false);

    const handleConcludeUpdateSection2HeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSection2HeadingPopUp(false);
        }
        else{
            setShowUpdateSection2HeadingPopUp(false);
            handleGetSection2DetailsRequest();
        }
    }

    const [showAddSection2LinkPopUp, setShowAddSection2LinkPopUp] = useState(false);

    const handleConcludeAddSection2LinkPopUp = async (e) => {
        if(e.status === false){
            setShowAddSection2LinkPopUp(false);
        }
        else{
            setShowAddSection2LinkPopUp(false);
            handleGetSection2DetailsRequest();
        }
    }

    const handleSaveSortedSection2LinksList = async (list) => {

        const sortSectionLinksReq = await FooterServices.sortSection2LinkItems({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortSectionLinksReq.status === false){
            ToastAlert.notifyError(sortSectionLinksReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortSectionLinksReq.message);
            handleGetSection2DetailsRequest();
        }
    }

    const handleSortSection2LinksEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(section2Details.links, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedSection2LinksList(entityIdWithIndex);

        let data = {
            heading: section2Details.heading,
            links: [...arrayMove]
        }

        setSection2Details(data);
    }

    const [section3Details, setSection3Details] = useState([]);

    const handleGetSection3DetailsRequest = async () => {
        const getSection3DetailsReq = await FooterServices.getAuthSection3Details({
            token: user.access_token
        });

        if(getSection3DetailsReq.status === false){
            ToastAlert.notifyError(getSection3DetailsReq.message);
            setSection3Details([]);
        }
        else{
            setSection3Details(getSection3DetailsReq.data);
            console.log(getSection3DetailsReq.data);
        }
    }

    useEffect(() => {
        handleGetSection3DetailsRequest();
    }, []);

    const [showUpdateSection3HeadingPopUp, setShowUpdateSection3HeadingPopUp] = useState(false);

    const handleConcludeUpdateSection3HeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSection3HeadingPopUp(false);
        }
        else{
            setShowUpdateSection3HeadingPopUp(false);
            handleGetSection3DetailsRequest();
        }
    }

    const [showAddSection3LinkPopUp, setShowAddSection3LinkPopUp] = useState(false);

    const handleConcludeAddSection3LinkPopUp = async (e) => {
        if(e.status === false){
            setShowAddSection3LinkPopUp(false);
        }
        else{
            setShowAddSection3LinkPopUp(false);
            handleGetSection3DetailsRequest();
        }
    }

    const handleSaveSortedSection3LinksList = async (list) => {

        const sortSectionLinksReq = await FooterServices.sortSection3LinkItems({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortSectionLinksReq.status === false){
            ToastAlert.notifyError(sortSectionLinksReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortSectionLinksReq.message);
            handleGetSection3DetailsRequest();
        }
    }

    const handleSortSection3LinksEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(section3Details.links, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedSection3LinksList(entityIdWithIndex);

        let data = {
            heading: section3Details.heading,
            links: [...arrayMove]
        }

        setSection3Details(data);
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Footer'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to footer in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>
            
            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"Section Headings"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSectionHeadingsPopUp(true)} isBottomAction={false}>
                    
                    {
                        sectionHeadings === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No section headings found!"}
                                    description={"Update section headings by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => setShowUpdateSectionHeadingsPopUp(true)}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => console.log("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"description"} 
                                    description={defaults.capitalize(sectionHeadings?.description)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItemWithPreview 
                                    lable={`Logo`}
                                    preview={`${globals.API_BASE_URL}${sectionHeadings?.logo?.image_url?.full?.high_res}`}
                                    previewType={"image"}
                                    fileName={sectionHeadings?.logo?.name?.original}
                                    fileExtension={sectionHeadings?.logo?.extension?.original}
                                    fileSize={sectionHeadings?.logo?.size?.current}
                                />
                            </DetailsRow>
                        </PlainContainer>
                    }
                </Card>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Section 1"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSection1HeadingPopUp(true)} isBottomAction={false}>
                        {
                            section1Details === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No section 1 details found!"}
                                        description={"Update section 1 details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateSection1HeadingPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => console.log("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"heading"} 
                                        description={defaults.capitalize(section1Details?.heading)} 
                                    />
                                </DetailsRow>
                                
                                <PlainContainer type='full' styles={{marginTop: "10px"}}>
                                    <Card hasHeading={true} heading={"Section 1 Links"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddSection1LinkPopUp(true)} isBottomAction={false}>
                                        <Table>
                                            <TableHeading>
                                                <TableHeadingItem size={2} lable={"lable"} />
                                                <TableHeadingItem size={1} lable={"go to type"} />
                                                <TableHeadingItem size={2} lable={"go to"} />
                                                <TableHeadingItem size={1} lable={"sub items"} />
                                                <TableHeadingItem size={1} lable={"created by"} />
                                                <TableHeadingItem size={2} lable={"Actions"} />
                                            </TableHeading>

                                            <SortableSection1LinksContainer onSortEnd={handleSortSection1LinksEnd} useDragHandle>
                                                {section1Details?.links?.map((item, index) => (
                                                    <SortableSection1LinkItem key={`item-${index}`} index={index} value={item} />
                                                ))}
                                            </SortableSection1LinksContainer>
                                        </Table>
                                    </Card>
                                </PlainContainer>
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Section 2"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSection2HeadingPopUp(true)} isBottomAction={false}>
                        {
                            section2Details === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No section 2 details found!"}
                                        description={"Update section 2 details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateSection2HeadingPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => console.log("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"heading"} 
                                        description={defaults.capitalize(section2Details?.heading)} 
                                    />
                                </DetailsRow>
                                
                                <PlainContainer type='full' styles={{marginTop: "10px"}}>
                                    <Card hasHeading={true} heading={"Section 2 Links"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddSection2LinkPopUp(true)} isBottomAction={false}>
                                        <Table>
                                            <TableHeading>
                                                <TableHeadingItem size={2} lable={"lable"} />
                                                <TableHeadingItem size={1} lable={"go to type"} />
                                                <TableHeadingItem size={2} lable={"go to"} />
                                                <TableHeadingItem size={1} lable={"sub items"} />
                                                <TableHeadingItem size={1} lable={"created by"} />
                                                <TableHeadingItem size={2} lable={"Actions"} />
                                            </TableHeading>

                                            <SortableSection2LinksContainer onSortEnd={handleSortSection2LinksEnd} useDragHandle>
                                                {section2Details?.links?.map((item, index) => (
                                                    <SortableSection2LinkItem key={`item-${index}`} index={index} value={item} />
                                                ))}
                                            </SortableSection2LinksContainer>
                                        </Table>
                                    </Card>
                                </PlainContainer>
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Section 3"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSection3HeadingPopUp(true)} isBottomAction={false}>
                        {
                            section3Details === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No section 3 details found!"}
                                        description={"Update section 3 details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateSection3HeadingPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => console.log("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"heading"} 
                                        description={defaults.capitalize(section3Details?.heading)} 
                                    />
                                </DetailsRow>
                                
                                <PlainContainer type='full' styles={{marginTop: "10px"}}>
                                    <Card hasHeading={true} heading={"Section 3 Links"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddSection3LinkPopUp(true)} isBottomAction={false}>
                                        <Table>
                                            <TableHeading>
                                                <TableHeadingItem size={2} lable={"lable"} />
                                                <TableHeadingItem size={1} lable={"go to type"} />
                                                <TableHeadingItem size={2} lable={"go to"} />
                                                <TableHeadingItem size={1} lable={"sub items"} />
                                                <TableHeadingItem size={1} lable={"created by"} />
                                                <TableHeadingItem size={2} lable={"Actions"} />
                                            </TableHeading>

                                            <SortableSection3LinksContainer onSortEnd={handleSortSection3LinksEnd} useDragHandle>
                                                {section3Details?.links?.map((item, index) => (
                                                    <SortableSection3LinkItem key={`item-${index}`} index={index} value={item} />
                                                ))}
                                            </SortableSection3LinksContainer>
                                        </Table>
                                    </Card>
                                </PlainContainer>
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Contact Details"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateContactDetailsPopUp(true)} isBottomAction={false}>
                        {
                            contactDetails === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No contact details found!"}
                                        description={"Update contact details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateContactDetailsPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => console.log("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"address"} 
                                        description={defaults.capitalize(contactDetails?.address)} 
                                    />
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"phone numbers"} 
                                        description={defaults.capitalize(contactDetails?.phone_number)} 
                                    />
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"email IDs"} 
                                        description={defaults.capitalize(contactDetails?.email_id)} 
                                    />
                                </DetailsRow>
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Copyright Details"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateCopyrightDetailsPopUp(true)} isBottomAction={false}>
                        {
                            contactDetails === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No copyright details found!"}
                                        description={"Update copyright details by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateCopyrightDetailsPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => console.log("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"year"} 
                                        description={defaults.capitalize(copyrightDetails?.copyright?.year)} 
                                    />
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"company name"} 
                                        description={defaults.capitalize(copyrightDetails?.copyright?.company_name)} 
                                    />
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"partner name"} 
                                        description={defaults.capitalize(copyrightDetails?.powered_by?.partner_name)} 
                                    />
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"action"} 
                                        description={defaults.capitalize(copyrightDetails?.powered_by?.action)} 
                                    />
                                </DetailsRow>
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>
            </PlainContainer>

            {
                showUpdateSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateSectionHeadingPopUp({status: false})}>
                    <UpdateFooterSectionHeadingsPopUp user={user} section_headings={sectionHeadings} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateSectionHeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateContactDetailsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Contact Details" closePopUp={() => handleConcludeUpdateContactDetailsPopUp({status: false})}>
                    <UpdateFooterContactDetailsPopUp user={user} contact_us={contactDetails} concludeUpdateContactDetailsPopUp={(e) => handleConcludeUpdateContactDetailsPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateCopyrightDetailsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Copyright Details" closePopUp={() => handleConcludeUpdateCopyrightDetailsPopUp({status: false})}>
                    <UpdateFooterCopyrightDetailsPopUp user={user} copyright_details={copyrightDetails} concludeUpdateCopyrightDetailsPopUp={(e) => handleConcludeUpdateCopyrightDetailsPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateSection1HeadingPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section 1 Heading" closePopUp={() => handleConcludeUpdateSection1HeadingPopUp({status: false})}>
                    <UpdateFooterSection1HeadingPopUp user={user} section={section1Details} concludeUpdateSection1HeadingPopUp={(e) => handleConcludeUpdateSection1HeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddSection1LinkPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add Section 1 Link" closePopUp={() => handleConcludeAddSection1LinkPopUp({status: false})}>
                    <AddFooterSection1LinkItemPopUp user={user} concludeAddSection1LinkItemPopUp={(e) => handleConcludeAddSection1LinkPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateSection2HeadingPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section 2 Heading" closePopUp={() => handleConcludeUpdateSection2HeadingPopUp({status: false})}>
                    <UpdateFooterSection2HeadingPopUp user={user} section={section2Details} concludeUpdateSection2HeadingPopUp={(e) => handleConcludeUpdateSection2HeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddSection2LinkPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add Section 2 Link" closePopUp={() => handleConcludeAddSection2LinkPopUp({status: false})}>
                    <AddFooterSection2LinkItemPopUp user={user} concludeAddSection2LinkItemPopUp={(e) => handleConcludeAddSection2LinkPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateSection3HeadingPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section 3 Heading" closePopUp={() => handleConcludeUpdateSection3HeadingPopUp({status: false})}>
                    <UpdateFooterSection3HeadingPopUp user={user} section={section3Details} concludeUpdateSection3HeadingPopUp={(e) => handleConcludeUpdateSection3HeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddSection3LinkPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add Section 3 Link" closePopUp={() => handleConcludeAddSection3LinkPopUp({status: false})}>
                    <AddFooterSection3LinkItemPopUp user={user} concludeAddSection3LinkItemPopUp={(e) => handleConcludeAddSection3LinkPopUp(e)} />
                </PopUp>
                :
                <></>
            }
        </>
    )
}

export default FooterPage