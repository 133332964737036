import React from 'react'

const PreLoginSectionLogos = () => {
    return (
        <div className="preLoginFormSectionLogos">
            <div className="preLoginFormSectionLogoSingle">
                <img src="images/uniwear-logo-landscape.png" className="preLoginFormSectionLogoSingleImg" alt="Karnataka State" />
            </div>
            <div className="preLoginFormSectionLogoSeperator">
                <div className="preLoginFormSectionLogoSeperatorMain">&nbsp;</div>
            </div>
            <div className="preLoginFormSectionLogoSingle">
                <img src="images/logo.png" className="preLoginFormSectionLogoSingleImg" alt="Karnataka State" />
            </div>
        </div>
    )
}

export default PreLoginSectionLogos