module.exports.folderName = {
        type: "text",
        hasLable: true,
        lable: "folder name",
        is_mandatory: true,
        is_limited: true,
        max_characters: 35,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Folder Name"
}

module.exports.subHeaderActionLable = {
        type: "text",
        hasLable: true,
        lable: "lable",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Action Lable"
}

module.exports.subHeaderActionIcon = {
        type: "text",
        hasLable: true,
        lable: "icon",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Action Icon Class"
}

module.exports.subHeaderActionURL = {
        type: "text",
        hasLable: true,
        lable: "action",
        is_mandatory: false,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Action URL"
}

module.exports.primaryNavigationItemLable = {
        type: "text",
        hasLable: true,
        lable: "lable",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Lable"
}

module.exports.primaryNavigationItemGotoPage = {
        hasLable: true,
        lable: "Go To Page",
        is_mandatory: true,
        placeholder: "Select Page",
        value: 0
}

module.exports.primaryNavigationItemGotoURL = {
        type: "text",
        hasLable: true,
        lable: "Go To URL",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Go To URL"
}

module.exports.headerActionLable = {
        type: "text",
        hasLable: true,
        lable: "Lable",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Lable"
}

module.exports.headerActionValue = {
        type: "text",
        hasLable: true,
        lable: "Value",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Value"
}

module.exports.whyChooseUsTagline = {
        type: "text",
        hasLable: true,
        lable: "Tagline",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Tagline"
}

module.exports.whyChooseUsHeading = {
        type: "text",
        hasLable: true,
        lable: "Heading",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Heading"
}

module.exports.whyChooseUsDescription = {
        type: "text",
        hasLable: true,
        lable: "Description",
        is_mandatory: true,
        is_limited: false,
        max_characters: 180,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Description"
}

module.exports.whyChooseUsHighlightLable = {
        type: "text",
        hasLable: true,
        lable: "Lable",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Lable"
}

module.exports.whyChooseUsHighlightDescription = {
        type: "text",
        hasLable: true,
        lable: "Description",
        is_mandatory: true,
        is_limited: false,
        max_characters: 180,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Description"
}

module.exports.testimonialReviewClientName = {
        type: "text",
        hasLable: true,
        lable: "Client Name",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Client Name"
}

module.exports.testimonialReviewDescription = {
        type: "text",
        hasLable: true,
        lable: "Review",
        is_mandatory: true,
        is_limited: false,
        max_characters: 180,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Review"
}

module.exports.footerSectionHeadingsDescription = {
        type: "text",
        hasLable: true,
        lable: "description",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Description"
}

module.exports.footerContactUsAddress = {
        type: "text",
        hasLable: true,
        lable: "address",
        is_mandatory: true,
        is_limited: false,
        max_characters: 250,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Address"
}

module.exports.footerContactUsPhoneNumber = {
        type: "text",
        hasLable: true,
        lable: "phone number",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Phone Numbers"
}

module.exports.footerContactUsEmailID = {
        type: "text",
        hasLable: true,
        lable: "email ID",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Email IDs"
}

module.exports.footerCopyrightYear = {
        type: "text",
        hasLable: true,
        lable: "year",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Year"
}

module.exports.footerCopyrightCompanyName = {
        type: "text",
        hasLable: true,
        lable: "company name",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Company Name"
}

module.exports.footerCopyrightPartnerName = {
        type: "text",
        hasLable: true,
        lable: "partner name",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Partner Name"
}

module.exports.footerCopyrightAction = {
        type: "text",
        hasLable: true,
        lable: "action",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Action"
}

module.exports.footerSectionsHeading = {
        type: "text",
        hasLable: true,
        lable: "heading",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Heading"
}

module.exports.addHomePageBannerName = {
        type: "text",
        hasLable: true,
        lable: "name",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Name"
}

module.exports.addHomePageBannerAction = {
        type: "text",
        hasLable: true,
        lable: "action",
        is_mandatory: false,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Action"
}

module.exports.updateHomePageCategoryHeadingDescription = {
        type: "text",
        hasLable: true,
        lable: "description",
        is_mandatory: false,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Description"
}

module.exports.updateHomePageCategoryHeadingButtonLable = {
        type: "text",
        hasLable: true,
        lable: "button lable",
        is_mandatory: false,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Button Lable"
}

module.exports.updateHomePageCategoryHeadingButtonAction = {
        type: "text",
        hasLable: true,
        lable: "button action",
        is_mandatory: false,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Button Action"
}

module.exports.addHomePageCategoryItemLable = {
        type: "text",
        hasLable: true,
        lable: "lable",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Button Lable"
}

module.exports.updateHomePageAboutStat1Lable = {
        type: "text",
        hasLable: true,
        lable: "stat 1 lable",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Stat 1 Lable"
}

module.exports.updateHomePageAboutStat1Count = {
        type: "text",
        hasLable: true,
        lable: "stat 1 count",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Stat 1 Count"
}

module.exports.updateHomePageAboutStat2Lable = {
        type: "text",
        hasLable: true,
        lable: "stat 2 lable",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Stat 2 Lable"
}

module.exports.updateHomePageAboutStat2Count = {
        type: "text",
        hasLable: true,
        lable: "stat 2 count",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Stat 2 Count"
}

module.exports.updateHomePageAboutActionPrimaryLable = {
        type: "text",
        hasLable: true,
        lable: "primary - lable",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Primary Lable"
}

module.exports.updateHomePageAboutActionPrimaryAction = {
        type: "text",
        hasLable: true,
        lable: "primary - action",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Primary Action"
}

module.exports.updateHomePageAboutActionSecondaryLable = {
        type: "text",
        hasLable: true,
        lable: "secondary - lable",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Secondary Lable"
}

module.exports.updateHomePageAboutActionSecondaryValue = {
        type: "text",
        hasLable: true,
        lable: "secondary - value",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Secondary Value"
}

module.exports.updateHomePageAboutActionSecondaryAction = {
        type: "text",
        hasLable: true,
        lable: "secondary - action",
        is_mandatory: true,
        is_limited: false,
        max_characters: 100,
        min_characters: 3,
        is_capital: false,
        requires_validation: true,
        validation_type: "alphaNumericWithHyphenAndSpace",
        placeholder: "Enter Secondary Action"
}