import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import {ContactUsPageServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';

const ContactUsPage = ({ user }) => {
    const navigate = useNavigate();

    const [seo, setSEO] = useState(null);

    const handleGetSEORequest = async () => {
        const getSEOReq = await ContactUsPageServices.getAuthSEO({
            token: user.access_token
        });

        if(getSEOReq.status === false){
            ToastAlert.notifyError(getSEOReq.message);
            setSEO(null);
        }
        else{
            console.log(getSEOReq.data);
            setSEO(getSEOReq.data);
        }
    }

    useEffect(() => {
        handleGetSEORequest();
    }, []);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Contact Us Page'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to Contact Us page in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"SEO"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate("seo")} isBottomAction={false}>
                    {
                        seo === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No SEO Details found!"}
                                    description={"Update seo details by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => navigate("seo")}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetSEORequest("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            {
                                seo?.title != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"title"} 
                                        description={defaults.capitalize(seo?.title)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.description != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"description"} 
                                        description={defaults.capitalize(seo?.description)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.keywords != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"keywords"} 
                                        description={defaults.capitalize(seo?.keywords)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.canonical_url != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"canonical URL"} 
                                        description={defaults.capitalize(seo?.canonical_url)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.twitter_handler != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"twitter handler"} 
                                        description={defaults.capitalize(seo?.twitter_handler)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.image != undefined
                                ?
                                <DetailsRow>
                                    <DetailsItemWithPreview 
                                        lable={`Image`}
                                        preview={`${globals.API_BASE_URL}${seo?.image?.image_url?.full?.high_res}`}
                                        previewType={"image"}
                                        fileName={seo?.image?.name?.original}
                                        fileExtension={seo?.image?.extension?.original}
                                        fileSize={seo?.image?.size?.current}
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                        </PlainContainer>
                    }
                </Card>
            </PlainContainer>
        </>
    )
}

export default ContactUsPage