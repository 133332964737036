import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import { HomePageServices } from '../../../../../services';

const UpdateHomePageAboutCoverImagesPopUp = ({ user, cover_images, concludeUpdateCoverImagesPopUp }) => {

    const [selectedPortImages, setSelectedPortImages] = useState([]);
    const [portImageType, setPortImageType] = useState("image");
    const [portImageIsMultiple, setPortImageIsMultiple] = useState(false);
    const [portImageMaxFileSelection, setPortImageMaxFileSelection] = useState(1);
    const [portImagePopUpLable, setPortImagePopUpLable] = useState("Portrait Image");
    const [portImageIsError, setPortImageIsError] = useState(false);
    const [portImageData, setPortImageData] = useState(null);

    const [selectedSquare1Images, setSelectedSquare1Images] = useState([]);
    const [square1ImageType, setSquare1ImageType] = useState("image");
    const [square1ImageIsMultiple, setSquare1ImageIsMultiple] = useState(false);
    const [square1ImageMaxFileSelection, setSquare1ImageMaxFileSelection] = useState(1);
    const [square1ImagePopUpLable, setSquare1ImagePopUpLable] = useState("Square 1 Image");
    const [square1ImageIsError, setSquare1ImageIsError] = useState(false);
    const [square1ImageData, setSquare1ImageData] = useState(null);

    const [selectedSquare2Images, setSelectedSquare2Images] = useState([]);
    const [square2ImageType, setSquare2ImageType] = useState("image");
    const [square2ImageIsMultiple, setSquare2ImageIsMultiple] = useState(false);
    const [square2ImageMaxFileSelection, setSquare2ImageMaxFileSelection] = useState(1);
    const [square2ImagePopUpLable, setSquare2ImagePopUpLable] = useState("Square 2 Image");
    const [square2ImageIsError, setSquare2ImageIsError] = useState(false);
    const [square2ImageData, setSquare2ImageData] = useState(null);

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handlePortImageSelection = async (e) => {
        if(e.status === true){
            setSelectedPortImages([...e.files]);
            setPortImageIsError(false);
        }
        else{
            setSelectedPortImages([]);
        }
    }

    const handleSquare1ImageSelection = async (e) => {
        if(e.status === true){
            setSelectedSquare1Images([...e.files]);
            setSquare1ImageIsError(false);
        }
        else{
            setSelectedSquare1Images([]);
        }
    }

    const handleSquare2ImageSelection = async (e) => {
        if(e.status === true){
            setSelectedSquare2Images([...e.files]);
            setSquare2ImageIsError(false);
        }
        else{
            setSelectedSquare2Images([]);
        }
    }

    const handleUpdateCoverImageSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(cover_images === null){
            if(selectedPortImages.length === 0){
                setPortImageIsError(true);
            }
            else{
                setPortImageIsError(false);
            }

            if(selectedSquare1Images.length === 0){
                setSquare1ImageIsError(true);
            }
            else{
                setSquare1ImageIsError(false);
            }

            if(selectedSquare2Images.length === 0){
                setSquare2ImageIsError(true);
            }
            else{
                setSquare2ImageIsError(false);
            }

            const saveCoverImagesReq = await HomePageServices.updateAboutCoverImages({
                image_portrait: selectedPortImages[0] ,
                image_square_top: selectedSquare1Images[0],
                image_square_bottom: selectedSquare2Images[0],
                token: user.access_token
            });

            if(saveCoverImagesReq.status === false){
                ToastAlert.notifyError(saveCoverImagesReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveCoverImagesReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateCoverImagesPopUp({
                    status: true
                })
            }

        }
        else{
            const saveCoverImagesReq = await HomePageServices.updateAboutCoverImages({
                image_portrait: selectedPortImages.length > 0 ? selectedPortImages[0] : cover_images.portrait.id,
                image_square_top: selectedSquare1Images.length > 0 ? selectedSquare1Images[0] : cover_images.square_top.id,
                image_square_bottom: selectedSquare2Images.length > 0 ? selectedSquare2Images[0] : cover_images.square_bottom.id,
                token: user.access_token
            });

            if(saveCoverImagesReq.status === false){
                ToastAlert.notifyError(saveCoverImagesReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveCoverImagesReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateCoverImagesPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateCoverImageSubmission(e)} noValidate>
                <div className="cmsForm">
                    <FileUploadInput 
                        data={portImageData}
                        user={user} 
                        lable={portImagePopUpLable} 
                        acceptedFileType={portImageType} 
                        isPreview={cover_images === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${cover_images?.portrait?.image_url?.full?.high_res}`}
                        isMultiple={portImageIsMultiple} 
                        maxSelection={portImageMaxFileSelection} 
                        isError={portImageIsError}
                        action={(e) => handlePortImageSelection(e)} 
                    />

                    <FileUploadInput 
                        data={square1ImageData}
                        user={user} 
                        lable={square1ImagePopUpLable} 
                        acceptedFileType={square1ImageType} 
                        isPreview={cover_images === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${cover_images?.square_top?.image_url?.full?.high_res}`}
                        isMultiple={square1ImageIsMultiple} 
                        maxSelection={square1ImageMaxFileSelection} 
                        isError={square1ImageIsError}
                        action={(e) => handleSquare1ImageSelection(e)} 
                    />

                    <FileUploadInput 
                        data={square2ImageData}
                        user={user} 
                        lable={square2ImagePopUpLable} 
                        acceptedFileType={square2ImageType} 
                        isPreview={cover_images === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${cover_images?.square_bottom?.image_url?.full?.high_res}`}
                        isMultiple={square2ImageIsMultiple} 
                        maxSelection={square2ImageMaxFileSelection} 
                        isError={square2ImageIsError}
                        action={(e) => handleSquare2ImageSelection(e)} 
                    />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateCoverImagesPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )

}

export default UpdateHomePageAboutCoverImagesPopUp