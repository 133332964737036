import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import { HomePageServices } from '../../../../../services';

const UpdateHomePageBlogActionPopUp = ({ user, action, concludeUpdateActionPopUp }) => {
    
    const [primaryLable, setPrimaryLable] = useState(action != null ? action.primary.lable : "");
    const [hasPrimaryLableMessage, setHasPrimaryLableMessage] = useState(false);
    const [primaryLableMessage, setPrimaryLableMessage] = useState("");
    const [primaryLableMessageType, setPrimaryLableMessageType] = useState("");

    const [primaryAction, setPrimaryAction] = useState(action != null ? action.primary.action : "");
    const [hasPrimaryActionMessage, setHasPrimaryActionMessage] = useState(false);
    const [primaryActionMessage, setPrimaryActionMessage] = useState("");
    const [primaryActionMessageType, setPrimaryActionMessageType] = useState("");
    
    const [secondaryLable, setSecondaryLable] = useState(action != null ? action.secondary.lable : "");
    const [hasSecondaryLableMessage, setHasSecondaryLableMessage] = useState(false);
    const [secondaryLableMessage, setSecondaryLableMessage] = useState("");
    const [secondaryLableMessageType, setSecondaryLableMessageType] = useState("");

    const [secondaryAction, setSecondaryAction] = useState(action != null ? action.secondary.action : "");
    const [hasSecondaryActionMessage, setHasSecondaryActionMessage] = useState(false);
    const [secondaryActionMessage, setSecondaryActionMessage] = useState("");
    const [secondaryActionMessageType, setSecondaryActionMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateActionSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(primaryLable === ""){
            setHasPrimaryLableMessage(true);
            setPrimaryLableMessage("Invalid Primary Lable");
            setPrimaryLableMessageType("error");
        }
        else{
            setHasPrimaryLableMessage(false);
            setPrimaryLableMessage("");
            setPrimaryLableMessageType("");
        }

        if(primaryAction === ""){
            setHasPrimaryActionMessage(true);
            setPrimaryActionMessage("Invalid Primary Action");
            setPrimaryActionMessageType("error");
        }
        else{
            setHasPrimaryActionMessage(false);
            setPrimaryActionMessage("");
            setPrimaryActionMessageType("");
        }

        if(secondaryLable === ""){
            setHasSecondaryLableMessage(true);
            setSecondaryLableMessage("Invalid Secondary Action");
            setSecondaryLableMessageType("error");
        }
        else{
            setHasSecondaryLableMessage(false);
            setSecondaryLableMessage("");
            setSecondaryLableMessageType("");
        }

        if(secondaryAction === ""){
            setHasSecondaryActionMessage(true);
            setSecondaryActionMessage("Invalid Secondary Action");
            setSecondaryActionMessageType("error");
        }
        else{
            setHasSecondaryActionMessage(false);
            setSecondaryActionMessage("");
            setSecondaryActionMessageType("");
        }

        if(primaryLable === "" || primaryAction === "" || secondaryAction === "" || secondaryLable === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveBlogActionsReq = await HomePageServices.updateBlogActions({
                primary_lable: primaryLable,
                primary_action: primaryAction,
                secondary_lable: secondaryLable,
                secondary_action: secondaryAction,
                token: user.access_token
            });

            if(saveBlogActionsReq.status === false){
                ToastAlert.notifyError(saveBlogActionsReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveBlogActionsReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateActionPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateActionSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutActionPrimaryLable.hasLable}
                        lable={inputs.updateHomePageAboutActionPrimaryLable.lable}
                        isMandatory={inputs.updateHomePageAboutActionPrimaryLable.is_mandatory}
                        hasMessage={hasPrimaryLableMessage}
                        message={primaryLableMessage}
                        messageType={primaryLableMessageType}
                        isLimited={inputs.updateHomePageAboutActionPrimaryLable.is_limited}
                        limit={inputs.updateHomePageAboutActionPrimaryLable.max_characters}
                        value={primaryLable}
                    >
                        <input 
                            type={inputs.updateHomePageAboutActionPrimaryLable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutActionPrimaryLable.placeholder}
                            onChange={(e) => setPrimaryLable(e.target.value)}
                            value={primaryLable}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutActionPrimaryAction.hasLable}
                        lable={inputs.updateHomePageAboutActionPrimaryAction.lable}
                        isMandatory={inputs.updateHomePageAboutActionPrimaryAction.is_mandatory}
                        hasMessage={hasPrimaryActionMessage}
                        message={primaryActionMessage}
                        messageType={primaryActionMessageType}
                        isLimited={inputs.updateHomePageAboutActionPrimaryAction.is_limited}
                        limit={inputs.updateHomePageAboutActionPrimaryAction.max_characters}
                        value={primaryAction}
                    >
                        <input 
                            type={inputs.updateHomePageAboutActionPrimaryAction.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutActionPrimaryAction.placeholder}
                            onChange={(e) => setPrimaryAction(e.target.value)}
                            value={primaryAction}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutActionSecondaryLable.hasLable}
                        lable={inputs.updateHomePageAboutActionSecondaryLable.lable}
                        isMandatory={inputs.updateHomePageAboutActionSecondaryLable.is_mandatory}
                        hasMessage={hasSecondaryLableMessage}
                        message={secondaryLableMessage}
                        messageType={secondaryLableMessageType}
                        isLimited={inputs.updateHomePageAboutActionSecondaryLable.is_limited}
                        limit={inputs.updateHomePageAboutActionSecondaryLable.max_characters}
                        value={secondaryLable}
                    >
                        <input 
                            type={inputs.updateHomePageAboutActionSecondaryLable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutActionSecondaryLable.placeholder}
                            onChange={(e) => setSecondaryLable(e.target.value)}
                            value={secondaryLable}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutActionSecondaryAction.hasLable}
                        lable={inputs.updateHomePageAboutActionSecondaryAction.lable}
                        isMandatory={inputs.updateHomePageAboutActionSecondaryAction.is_mandatory}
                        hasMessage={hasSecondaryActionMessage}
                        message={secondaryActionMessage}
                        messageType={secondaryActionMessageType}
                        isLimited={inputs.updateHomePageAboutActionSecondaryAction.is_limited}
                        limit={inputs.updateHomePageAboutActionSecondaryAction.max_characters}
                        value={secondaryAction}
                    >
                        <input 
                            type={inputs.updateHomePageAboutActionSecondaryAction.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutActionSecondaryAction.placeholder}
                            onChange={(e) => setSecondaryAction(e.target.value)}
                            value={secondaryAction}
                        />
                    </TextInputRT>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateActionPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )

}

export default UpdateHomePageBlogActionPopUp