import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import Card from '../../../components/core/dashboard/Card';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import CitiesListFilters from './CitiesListFilters';
import CityServices from '../../../services/Location/City';
import { ToastAlert } from '../../../utils';
import Pagination from '../../../components/core/table/pagination';

const CitiesListPage = ({ user }) => {
    const navigate = useNavigate();

    const [cities, setCities] = useState([]);
    const [totalCitiesCount, setTotalCitiesCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const handleGetCitiesRequest = async () => {
        const getCitiesReq = await CityServices.getAllCities({
            token: user.access_token,
            current_page: currentPage
        });

        if(getCitiesReq.status === false){
            ToastAlert.notifyError(getCitiesReq.message);
            setCities([]);
            setTotalCitiesCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            setCities(getCitiesReq.data.items);
            setTotalCitiesCount(getCitiesReq.data.total_items);
            setTotalPagesCount(getCitiesReq.data.total_pages);
            setCurrentPage(getCitiesReq.data?.current_page === undefined ? 1 : getCitiesReq.data.current_page);
        }
    }

    const handleDeleteCityRequest = async (id) => {

        const deleteCityReq = await CityServices.deleteCity({
            token: user.access_token,
            id: id
        });

        if(deleteCityReq.status === false){
            ToastAlert.notifyError(deleteCityReq.message);
            setCities([]);
            setTotalCitiesCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            ToastAlert.notifySuccess(deleteCityReq.message);
            handleGetCitiesRequest();
        }
    }

    useEffect(() => {
        handleGetCitiesRequest();
    }, [currentPage]);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Cities'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of All Cities in India.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <CitiesListFilters />
                        </PlainContainer>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem>
                                    <TableHeadingItem size={2} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"district"} />
                                    <TableHeadingItem size={1} lable={"state"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        cities.map((city, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem>
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={city?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={city?.district?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={city?.state?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={`${city?.created_by?.first_name} (${city?.created_by?.role === "SUPER_ADMIN" ? "super admin" : "admin"})`} />
                                                    <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`edit/${city.id}`)}>edit</TableButton>
                                                            <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteCityRequest(city.id)}>delete</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default CitiesListPage