import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT'
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { FooterServices } from '../../../../services';

const UpdateFooterSection3HeadingPopUp = ({ user, section, concludeUpdateSection3HeadingPopUp }) => {

    const [heading, setHeading] = useState(section != null ? section.heading : "");
    const [hasHeadingMessage, setHasHeadingMessage] = useState(false);
    const [headingMessage, setHeadingMessage] = useState("");
    const [headingMessageType, setHeadingMessageType] = useState("");
    
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateSectionHeadingSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(heading.trim() === ""){
            setHasHeadingMessage(true);
            setHeadingMessage("Invalid Heading");
            setHeadingMessageType("error");
        }
        else{
            setHasHeadingMessage(false);
            setHeadingMessage("");
            setHeadingMessageType("");
        }

        if(heading.trim() === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveSectionHeadingReq = await FooterServices.updateSection3Heading({
                heading: heading,
                token: user.access_token
            });

            if(saveSectionHeadingReq.status === false){
                ToastAlert.notifyError(saveSectionHeadingReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveSectionHeadingReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateSection3HeadingPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateSectionHeadingSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.footerSectionsHeading.hasLable}
                        lable={inputs.footerSectionsHeading.lable}
                        isMandatory={inputs.footerSectionsHeading.is_mandatory}
                        hasMessage={hasHeadingMessage}
                        message={headingMessage}
                        messageType={headingMessageType}
                        isLimited={inputs.footerSectionsHeading.is_limited}
                        limit={inputs.footerSectionsHeading.max_characters}
                        value={heading}
                    >
                        <input 
                            type={inputs.footerSectionsHeading.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerSectionsHeading.placeholder}
                            onChange={(e) => setHeading(e.target.value)}
                            value={heading}
                        />
                    </TextInputRT>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateSection3HeadingPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateFooterSection3HeadingPopUp