import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT'
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import { HomePageServices } from '../../../../../services';
import TextAreaInput from '../../../../core/form/TextAreaInput';

const UpdateHomePageCategorySectionHeadingsPopUp = ({ user, section, concludeUpdateCategorySectionHeadingsPopUp }) => {

    const [description, setDescription] = useState(section != null ? section.description : "");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [buttonLable, setButtonLable] = useState(section != null ? section.button.lable : "");
    const [hasButtonLableMessage, setHasButtonLableMessage] = useState(false);
    const [buttonLableMessage, setButtonLableMessage] = useState("");
    const [buttonLableMessageType, setButtonLableMessageType] = useState("");

    const [buttonAction, setButtonAction] = useState(section != null ? section.button.action : "");
    const [hasButtonActionMessage, setHasButtonActionMessage] = useState(false);
    const [buttonActionMessage, setButtonActionMessage] = useState("");
    const [buttonActionMessageType, setButtonActionMessageType] = useState("");
    
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateSectionHeadingSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(description.trim() === ""){
            setHasDescriptionMessage(true);
            setDescriptionMessage("Invalid Description");
            setDescriptionMessageType("error");
        }
        else{
            setHasDescriptionMessage(false);
            setDescriptionMessage("");
            setDescriptionMessageType("");
        }

        if(buttonLable.trim() === ""){
            setHasButtonLableMessage(true);
            setButtonLableMessage("Invalid Button Lable");
            setButtonLableMessageType("error");
        }
        else{
            setHasButtonLableMessage(false);
            setButtonLableMessage("");
            setButtonLableMessageType("");
        }

        if(buttonAction.trim() === ""){
            setHasButtonActionMessage(true);
            setButtonActionMessage("Invalid Button Action");
            setButtonActionMessageType("error");
        }
        else{
            setHasButtonActionMessage(false);
            setButtonActionMessage("");
            setButtonActionMessageType("");
        }

        if(description.trim() === "" || buttonLable.trim() === "" || buttonAction.trim() === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveSectionHeadingReq = await HomePageServices.updateCategorySectionHeadings({
                description: description,
                button_lable: buttonLable,
                button_action: buttonAction,
                token: user.access_token
            });

            if(saveSectionHeadingReq.status === false){
                ToastAlert.notifyError(saveSectionHeadingReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveSectionHeadingReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateCategorySectionHeadingsPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateSectionHeadingSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextAreaInput 
                        hasLable={inputs.updateHomePageCategoryHeadingDescription.hasLable}
                        lable={inputs.updateHomePageCategoryHeadingDescription.lable}
                        isMandatory={inputs.updateHomePageCategoryHeadingDescription.is_mandatory}
                        hasIcon={false}
                        hasMessage={hasDescriptionMessage}
                        message={descriptionMessage}
                        messageType={descriptionMessageType}
                    >
                        <textarea 
                            className="cmsFormStepInputTextArea" 
                            placeholder={"Enter Description"}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            value={description}
                        >
                        </textarea>
                    </TextAreaInput>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageCategoryHeadingButtonLable.hasLable}
                        lable={inputs.updateHomePageCategoryHeadingButtonLable.lable}
                        isMandatory={inputs.updateHomePageCategoryHeadingButtonLable.is_mandatory}
                        hasMessage={hasButtonLableMessage}
                        message={buttonLableMessage}
                        messageType={buttonLableMessageType}
                        isLimited={inputs.updateHomePageCategoryHeadingButtonLable.is_limited}
                        limit={inputs.updateHomePageCategoryHeadingButtonLable.max_characters}
                        value={buttonLable}
                    >
                        <input 
                            type={inputs.updateHomePageCategoryHeadingButtonLable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageCategoryHeadingButtonLable.placeholder}
                            onChange={(e) => setButtonLable(e.target.value)}
                            value={buttonLable}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageCategoryHeadingButtonAction.hasLable}
                        lable={inputs.updateHomePageCategoryHeadingButtonAction.lable}
                        isMandatory={inputs.updateHomePageCategoryHeadingButtonAction.is_mandatory}
                        hasMessage={hasButtonActionMessage}
                        message={buttonActionMessage}
                        messageType={buttonActionMessageType}
                        isLimited={inputs.updateHomePageCategoryHeadingButtonAction.is_limited}
                        limit={inputs.updateHomePageCategoryHeadingButtonAction.max_characters}
                        value={buttonAction}
                    >
                        <input 
                            type={inputs.updateHomePageCategoryHeadingButtonAction.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageCategoryHeadingButtonAction.placeholder}
                            onChange={(e) => setButtonAction(e.target.value)}
                            value={buttonAction}
                        />
                    </TextInputRT>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateCategorySectionHeadingsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateHomePageCategorySectionHeadingsPopUp