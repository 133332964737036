import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../../components/core/dashboard/Button';
import { icons } from '../../../../constants';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../components/core/containers/SplitContainer';
import Card from '../../../../components/core/dashboard/Card';
import CardHeading from '../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../components/core/typography/dashboard/CardDescription';
import TextInputRT from '../../../../components/core/form/TextInputRT';
import FormActions from '../../../../components/form/FormActions';
import { ToastAlert, validations } from '../../../../utils';
import StateServices from '../../../../services/Location/State';

const EditStatePage = ({ user }) => {
    const navigate = useNavigate();
    const params = useParams();

    const [enName, setEnName] = useState("");
    const [hasEnNameIcon, setHasEnNameIcon] = useState(false);
    const [enNameIcon, setEnNameIcon] = useState("");
    const [enNameIconPosition, setEnNameIconPosition] = useState("right");
    const [enNameIconType, setEnNameIconType] = useState("");
    const [hasEnNameMessage, setHasEnNameMessage] = useState(false);
    const [enNameMessage, setEnNameMessage] = useState("");
    const [enNameMessageType, setEnNameMessageType] = useState("");

    const handleGetThisStateReq = async () => {
        const getThisStateReq = await StateServices.getThisAuthState({
            id: params.id,
            token: user.access_token
        });

        if(getThisStateReq.status === false){
            ToastAlert.notifyError(getThisStateReq.message);
            setEnName("");
        }
        else{
            setEnName(getThisStateReq.data?.name?.en);
        }
    }

    useEffect(() => {
        handleGetThisStateReq();
    }, []);
    

    const enNameChanged = async (e) => {
        const isEnNameValid = await validations.disableSpecialCharacterUserName(e);
        if(isEnNameValid.status){
            setEnName(e);
        }
        else if(e.length === 0){
            setEnName('');
        }
    }

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddStateSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        const isEnNameValid = await validations.validateUserName(enName);

        if(isEnNameValid.status === false){
            setHasEnNameIcon(true);
            setEnNameIcon(icons.wrongCircle);
            setEnNameIconType("error");
            setHasEnNameMessage(true);
            setEnNameMessage("Invalid English Name");
            setEnNameMessageType("error");
        }
        else{
            setHasEnNameIcon(true);
            setEnNameIcon(icons.tickCircle);
            setEnNameIconType("success");
            setHasEnNameMessage(false);
            setEnNameMessage("");
            setEnNameMessageType("");
        }

        if(isEnNameValid.status === false){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveStateReq = await StateServices.updateState({
                id: params.id,
                en_name: enName,
                token: user.access_token
            });

            if(saveStateReq.status === false){
                ToastAlert.notifyError(saveStateReq.message);
                setSubmitButtonLoading(false);
                setHasEnNameIcon(false);
                setEnNameIcon("");
                setEnNameIconType("");
            }
            else{
                ToastAlert.notifySuccess(saveStateReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonHasIcon(true);
                setSubmitButtonLable('Success');
                navigate(-1);
            }
        }
    }

    return(
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='States'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of States in India.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.list}
                        action={()=> navigate('/masterdata/locations/states') }
                    >
                        View States
                    </Button>
                </HeaderActions>
            </DefaultHeader>
            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"edit state"} color="dark" />
                        <CardDescription description={"country: India"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleAddStateSubmission(e)} noValidate>
                                <div className="cmsForm">
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="English Name"
                                        isMandatory={true}
                                        hasIcon={hasEnNameIcon}
                                        icon={enNameIcon}
                                        iconPosition={enNameIconPosition}
                                        iconType={enNameIconType}
                                        hasMessage={hasEnNameMessage}
                                        message={enNameMessage}
                                        messageType={enNameMessageType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Name"}
                                            onChange={(e) => enNameChanged(e.target.value)}
                                            value={enName}
                                        />
                                    </TextInputRT>
                                    
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default EditStatePage