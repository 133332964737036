import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT';
import TextAreaInput from '../../../core/form/TextAreaInput';
import FileUploadInput from '../../../core/form/FileUploadInput';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { TestimonialServices } from '../../../../services';

const AddTestimonialReviewPopUp = ({ user, concludeAddReviewPopUp }) => {

    const [clientName, setClientName] = useState("");
    const [hasClientNameMessage, setHasClientNameMessage] = useState(false);
    const [clientNameMessage, setClientNameMessage] = useState("");
    const [clientNameMessageType, setClientNameMessageType] = useState("");

    const [review, setReview] = useState("");
    const [hasReviewMessage, setHasReviewMessage] = useState(false);
    const [reviewMessage, setReviewMessage] = useState("");
    const [reviewMessageType, setReviewMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Client Photo");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddReviewSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(clientName.trim() === ""){
            setHasClientNameMessage(true);
            setClientNameMessage("Invalid Client Name");
            setClientNameMessageType("error");
        }
        else{
            setHasClientNameMessage(false);
            setClientNameMessage("");
            setClientNameMessageType("");
        }

        if(review.trim() === ""){
            setHasReviewMessage(true);
            setReviewMessage("Invalid Review");
            setReviewMessageType("error");
        }
        else{
            setHasReviewMessage(false);
            setReviewMessage("");
            setReviewMessageType("");
        }

        if(selectedImages.length === 0){
            setImageIsError(true);
        }
        else{
            setImageIsError(false);
        }

        if(clientName.trim() === "" || review.trim() === "" || selectedImages.length === 0){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveReviewReq = await TestimonialServices.saveReview({
                client_name: clientName,
                review: review,
                image: selectedImages[0],
                token: user.access_token
            });

            if(saveReviewReq.status === false){
                ToastAlert.notifyError(saveReviewReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveReviewReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeAddReviewPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleAddReviewSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.testimonialReviewClientName.hasLable}
                        lable={inputs.testimonialReviewClientName.lable}
                        isMandatory={inputs.testimonialReviewClientName.is_mandatory}
                        hasMessage={hasClientNameMessage}
                        message={clientNameMessage}
                        messageType={clientNameMessageType}
                        isLimited={inputs.testimonialReviewClientName.is_limited}
                        limit={inputs.testimonialReviewClientName.max_characters}
                        value={clientName}
                    >
                        <input 
                            type={inputs.testimonialReviewClientName.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.testimonialReviewClientName.placeholder}
                            onChange={(e) => setClientName(e.target.value)}
                            value={clientName}
                        />
                    </TextInputRT>

                    <TextAreaInput 
                        hasLable={inputs.testimonialReviewDescription.hasLable}
                        lable={inputs.testimonialReviewDescription.lable}
                        isMandatory={inputs.testimonialReviewDescription.is_mandatory}
                        hasIcon={false}
                        hasMessage={hasReviewMessage}
                        message={reviewMessage}
                        messageType={reviewMessageType}
                    >
                        <textarea 
                            type="text" 
                            className="cmsFormStepInputTextArea" 
                            placeholder={"Enter Review"}
                            onChange={(e) => setReview(e.target.value)}
                            rows={3}
                            defaultValue={review}
                        >
                        </textarea>
                    </TextAreaInput>

                    <FileUploadInput 
                        data={imageData}
                        user={user} 
                        lable={imagePopUpLable} 
                        acceptedFileType={imageType} 
                        isPreview={false}
                        previewType={"image"}
                        preview={""}
                        isMultiple={imageIsMultiple} 
                        maxSelection={imageMaxFileSelection} 
                        isError={imageIsError}
                        action={(e) => handleImageSelection(e)} 
                    />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeAddReviewPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default AddTestimonialReviewPopUp