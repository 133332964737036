import React, { useState, useEffect, useRef, useMemo } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import { AboutPageServices, PageServices } from '../../../../../services';
import RadioButtonGroup from '../../../../core/form/RadioButtonGroup';
import RadioButtonInput from '../../../../core/form/RadioButtonInput';
import SelectInput from '../../../../core/form/SelectInput';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UpdateAboutUsPageDescriptionPopUp = ({ user, data, concludeUpdateDescriptionPopUp }) => {

    const quillRef = useRef();

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [
                    { 'header': [1, 2, 3, 4, 5, 6, false] }
                ],
                ['bold', 'italic', 'underline', "strike"],
                // ['image', "link"],
                // [
                //     { 'list': 'ordered' }, { 'list': 'bullet' },
                //     { 'indent': '-1' }, { 'indent': '+1' }
                // ],
                [{ 'align': [] }],
                // [
                //     { 'color': 
                //         ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] 
                //     }
                // ]
            ],
            // handlers: {
            //     image: imageHandler
            // }
        },
    }), []);

    const [description, setDescription] = useState(data === null ? 0 : data);
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateDescriptionSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(description == ""){
            setHasDescriptionMessage(true);
            setDescriptionMessage("Description is required");
            setDescriptionMessageType("error");
        }
        else{
            setHasDescriptionMessage(false);
            setDescriptionMessage("");
            setDescriptionMessageType("");
        }

        const updateDescriptionReq = await AboutPageServices.updateAboutDescription({
            description: description,
            token: user.access_token
        });

        if(updateDescriptionReq.status === false){
            ToastAlert.notifyError(updateDescriptionReq.message);
            setSubmitButtonLoading(false);
        }
        else{
            ToastAlert.notifySuccess(updateDescriptionReq.message);
            setSubmitButtonLoading(false);
            setSubmitButtonDisabled(true);
            setSubmitButtonBg('success');
            setSubmitButtonLable('Success');
            concludeUpdateDescriptionPopUp({
                status: true
            })
        }
        
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateDescriptionSubmission(e)} noValidate>
                <div className="cmsForm">

                    <ReactQuill modules={modules} ref={quillRef} style={{height: '500px', paddingBottom: '45px'}} theme="snow" value={description} onChange={setDescription} />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateDescriptionPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateAboutUsPageDescriptionPopUp