import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { RequestAQuoteServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import UpdateTestimonialSectionHeadingPopUp from '../../../components/dashboard/popups/Testimonial/UpdateTestimonialSectionHeadingPopUp';
import UpdateRequestQuoteSectionHeadingsPopUp from '../../../components/dashboard/popups/RequestAQuote/UpdateRequestQuoteSectionHeadingsPopUp';

const RequestAQuotePage = ({ user }) => {
    const navigate = useNavigate();

    const [sectionHeadings, setSectionHeadings] = useState(null);

    const [showUpdateSectionHeadingsPopUp, setShowUpdateSectionHeadingsPopUp] = useState(false);

    const handleGetSectionHeadingsRequest = async () => {
        const getSectionHeadingsReq = await RequestAQuoteServices.getAuthSectionHeadings({
            token: user.access_token
        });

        if(getSectionHeadingsReq.status === false){
            ToastAlert.notifyError(getSectionHeadingsReq.message);
            setSectionHeadings(null);
        }
        else{
            console.log(getSectionHeadingsReq.data);
            setSectionHeadings(getSectionHeadingsReq.data);
        }
    }

    useEffect(() => {
        handleGetSectionHeadingsRequest();
    }, []);

    const handleConcludeUpdateSectionHeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateSectionHeadingsPopUp(false);
            handleGetSectionHeadingsRequest();
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Request A Quote'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to request a quote in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"Section Headings"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSectionHeadingsPopUp(true)} isBottomAction={false}>
                    
                    {
                        sectionHeadings === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No section headings found!"}
                                    description={"Update section headings by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => setShowUpdateSectionHeadingsPopUp(true)}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetSectionHeadingsRequest()}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"section tagline"} 
                                    description={sectionHeadings?.tagline} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"section heading"} 
                                    description={sectionHeadings?.heading} 
                                />
                            </DetailsRow>
                            {
                                sectionHeadings.description != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"section description"} 
                                        description={sectionHeadings?.description} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            
                        </PlainContainer>
                    }
                </Card> 

            </PlainContainer>

            {
                showUpdateSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateSectionHeadingPopUp({status: false})}>
                    <UpdateRequestQuoteSectionHeadingsPopUp user={user} section_headings={sectionHeadings} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateSectionHeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }
        
        </>
    )
}

export default RequestAQuotePage