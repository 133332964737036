import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { HomePageServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';
import AddBannerHomePagePopUp from '../../../components/dashboard/popups/Pages/HomePage/AddBannerHomePagePopUp';
import UpdateHomePageCategorySectionHeadingsPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomePageCategorySectionHeadingsPopUp';
import AddCategoryHomePagePopUp from '../../../components/dashboard/popups/Pages/HomePage/AddCategoryHomePagePopUp';
import UpdateHomePageAboutSectionHeadingsPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomePageAboutSectionHeadingsPopUp';
import UpdateHomePageAboutCoverImagesPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomePageAboutCoverImagesPopUp';
import UpdateHomePageAboutStatsPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomePageAboutStatsPopUp';
import AddHomePageAboutHighlightPopUp from '../../../components/dashboard/popups/Pages/HomePage/AddHomePageAboutHighlightPopUp';
import UpdateHomepageAboutHighlightPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomepageAboutHighlightPopUp';
import UpdateHomePageAboutActionPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomePageAboutActionPopUp';
import UpdateHomePageProductSectionHeadingsPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomePageProductSectionHeadingsPopUp';
import UpdateHomePageProductActionPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomePageProductActionPopUp';
import UpdateHomePageBlogSectionHeadingsPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomePageBlogSectionHeadingsPopUp';
import UpdateHomePageBlogActionPopUp from '../../../components/dashboard/popups/Pages/HomePage/UpdateHomePageBlogActionsPopUp';

const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

const SortableBannerItem = sortableElement(({value, index}) => (
    <TableRow key={index}>
        <DragHandle />
        <TableRowItem size={4} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${value.images.web.image_url.thumbnail.low_res}`} lable={value.name} />
        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
        <TableRowItem size={2} isCenter={false} hasChildren={true}>
            <TableRowActions>
                <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => console.log(value)}>edit</TableButton>
                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(value.id)}>delete</TableButton>
            </TableRowActions>
        </TableRowItem>
    </TableRow>
));
  
const SortableBannerContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

const SortableCategoryListItem = sortableElement(({value, index}) => (
    <TableRow key={index}>
        <DragHandle />
        <TableRowItem size={4} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${value.icon.image_url.thumbnail.low_res}`} lable={value.lable} />
        <TableRowItem size={1} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? "page" : "URL"} />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? value?.action?.goto_page?.path : value?.action?.goto_url } />
        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
        <TableRowItem size={2} isCenter={false} hasChildren={true}>
            <TableRowActions>
                <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => console.log(value)}>edit</TableButton>
                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(value.id)}>delete</TableButton>
            </TableRowActions>
        </TableRowItem>
    </TableRow>
));
  
const SortableCategoryListContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

const HomePage = ({ user }) => {
    const navigate = useNavigate();

    const [seo, setSEO] = useState(null);

    const handleGetSEORequest = async () => {
        const getSEOReq = await HomePageServices.getAuthSEO({
            token: user.access_token
        });

        if(getSEOReq.status === false){
            ToastAlert.notifyError(getSEOReq.message);
            setSEO(null);
        }
        else{
            console.log(getSEOReq.data);
            setSEO(getSEOReq.data);
        }
    }

    useEffect(() => {
        handleGetSEORequest();
    }, []);

    const [banners, setBanners] = useState([]);

    const handleGetBannersRequest = async () => {
        const getBannersReq = await HomePageServices.getAuthBanners({
            token: user.access_token
        });

        if(getBannersReq.status === false){
            ToastAlert.notifyError(getBannersReq.message);
            setBanners([]);
        }
        else{
            console.log(getBannersReq.data);
            setBanners(getBannersReq.data);
        }
    }

    useEffect(() => {
        handleGetBannersRequest();
    }, []);

    const [showAddBannerPopUp, setShowAddBannerPopUp] = useState(false);

    const handleConcludeAddBannerPopUp = async (e) => {
        if(e.status === false){
            setShowAddBannerPopUp(false);
        }
        else{
            setShowAddBannerPopUp(false);
            handleGetBannersRequest();
        }
    }

    const handleSaveSortedBannersList = async (list) => {

        // const sortHighlghtsReq = await HomePageServices.sortHighlights({
        //     items: JSON.stringify(list),
        //     token: user.access_token
        // });

        // if(sortHighlghtsReq.status === false){
        //     ToastAlert.notifyError(sortHighlghtsReq.message);
        // }
        // else{
        //     ToastAlert.notifySuccess(sortHighlghtsReq.message);
        //     handleGetHighlightsRequest();
        // }
    }

    const handleSortBannersEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(banners, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedBannersList(entityIdWithIndex);

        setBanners([...arrayMove]);
    }
    
    const [categorySection, setCategorySection] = useState([]);

    const handleGetCategorySectionRequest = async () => {
        const getCategorySectionReq = await HomePageServices.getAuthCategorySection({
            token: user.access_token
        });

        if(getCategorySectionReq.status === false){
            ToastAlert.notifyError(getCategorySectionReq.message);
            setCategorySection([]);
        }
        else{
            console.log(getCategorySectionReq.data);
            setCategorySection(getCategorySectionReq.data);
        }
    }

    useEffect(() => {
        handleGetCategorySectionRequest();
    }, []);

    const [showUpdateCategoryHeadingsPopUp, setShowUpdateCategoryHeadingsPopUp] = useState(false);

    const handleConcludeUpdateCategorySectionHeadingsPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateCategoryHeadingsPopUp(false);
        }
        else{
            setShowUpdateCategoryHeadingsPopUp(false);
            handleGetCategorySectionRequest();
        }
    }

    const [showAddCategoryItemPopUp, setShowAddCategoryItemPopUp] = useState(false);

    const handleConcludeAddCategoryItemPopUp = async (e) => {
        if(e.status === false){
            setShowAddCategoryItemPopUp(false);
        }
        else{
            setShowAddCategoryItemPopUp(false);
            handleGetCategorySectionRequest();
        }
    }

    const handleSaveSortedCategoriesList = async (list) => {

        const sortCategoriesReq = await HomePageServices.sortCategoriesListItems({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortCategoriesReq.status === false){
            ToastAlert.notifyError(sortCategoriesReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortCategoriesReq.message);
            handleGetCategorySectionRequest();
        }
    }

    const handleSortCategoriesEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(categorySection.categories, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedCategoriesList(entityIdWithIndex);

        let data = {
            heading: categorySection.headings,
            categories: [...arrayMove]
        }

        setCategorySection(data);
    }
    
    const [aboutSection, setAboutSection] = useState(null);

    const handleGetAboutSectionRequest = async () => {
        const getAboutSectionReq = await HomePageServices.getAuthAboutSection({
            token: user.access_token
        });

        if(getAboutSectionReq.status === false){
            ToastAlert.notifyError(getAboutSectionReq.message);
            setAboutSection(null);
        }
        else{
            console.log(" home page",getAboutSectionReq.data);
            setAboutSection(getAboutSectionReq.data);
        }
    }

    useEffect(() => {
        handleGetAboutSectionRequest();
    }, []);

    const [showUpdateAboutSectionHeadingsPopUp, setShowUpdateAboutSectionHeadingsPopUp] = useState(false);

    const handleConcludeUpdateAboutSectionHeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateAboutSectionHeadingsPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    const [showUpdateAboutCoverImagesPopUp, setShowUpdateAboutCoverImagesPopUp] = useState(false);

    const handleConcludeUpdateAboutCoverImagesPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutCoverImagesPopUp(false);
        }
        else{
            setShowUpdateAboutCoverImagesPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    const [showUpdateAboutStatsPopUp, setShowUpdateAboutStatsPopUp] = useState(false);

    const handleConcludeUpdateAboutStatsPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutStatsPopUp(false);
        }
        else{
            setShowUpdateAboutStatsPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    const [showAddAboutHighlightPopUp, setShowAddAboutHighlightPopUp] = useState(false);

    const handleConcludeAddAboutHighlightPopUp = async (e) => {
        if(e.status === false){
            setShowAddAboutHighlightPopUp(false);
        }
        else{
            setShowAddAboutHighlightPopUp(false);
            handleGetAboutSectionRequest();
        }
    }
    
    const handleSaveAboutHighlightSortedList = async (list) => {

        const sortHighlghtsReq = await HomePageServices.sortAboutHighlights({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortHighlghtsReq.status === false){
            ToastAlert.notifyError(sortHighlghtsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortHighlghtsReq.message);
            handleGetAboutSectionRequest();
        }
    }

    const handleAboutHighlightSortEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(aboutSection.highlights, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveAboutHighlightSortedList(entityIdWithIndex);

        let data = {
            section_headings: aboutSection.section_headings,
            cover_images: aboutSection.cover_images,
            statistics: aboutSection.statistics,
            highlights: [...arrayMove]
        }

        setAboutSection(data);
    }

    const [showUpdateAboutHighlightPopUp, setShowUpdateAboutHighlightPopUp] = useState(false);
    const [aboutHighlightToUpdate, setAboutHighlightToUpdate] = useState(null);

    const handleShowUpdateAboutHighlightPopUp = async (data) => {
        console.log(data);
        setAboutHighlightToUpdate(data);
        setShowUpdateAboutHighlightPopUp(true);
    }

    const handleConcludeUpdateAboutHighlightPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutHighlightPopUp(false);
        }
        else{
            setShowUpdateAboutHighlightPopUp(false);
            handleGetAboutSectionRequest();
        }
    }

    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

    const SortableAboutHighlightItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={4} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${value.icon.image_url.thumbnail.low_res}`} lable={value.lable} />
            <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateAboutHighlightPopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
    
    const SortableAboutHighlightContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });

    const [showUpdateAboutActionPopUp, setShowUpdateAboutActionPopUp] = useState(false);

    const handleConcludeUpdateAboutActionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateAboutActionPopUp(false);
        }
        else{
            setShowUpdateAboutActionPopUp(false);
            handleGetAboutSectionRequest();
        }
    }
    
    const [productSection, setProductSection] = useState(null);

    const handleGetProductSectionRequest = async () => {
        const getProductSectionReq = await HomePageServices.getAuthProductSection({
            token: user.access_token
        });

        if(getProductSectionReq.status === false){
            ToastAlert.notifyError(getProductSectionReq.message);
            setProductSection(null);
        }
        else{
            console.log(getProductSectionReq.data);
            setProductSection(getProductSectionReq.data);
        }
    }

    useEffect(() => {
        handleGetProductSectionRequest();
    }, []);

    const [showUpdateProductSectionHeadingsPopUp, setShowUpdateProductSectionHeadingsPopUp] = useState(false);

    const handleConcludeUpdateProductSectionHeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateProductSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateProductSectionHeadingsPopUp(false);
            handleGetProductSectionRequest();
        }
    }

    const [showUpdateProductActionPopUp, setShowUpdateProductActionPopUp] = useState(false);

    const handleConcludeUpdateProductActionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateProductActionPopUp(false);
        }
        else{
            setShowUpdateProductActionPopUp(false);
            handleGetProductSectionRequest();
        }
    }
    
    const [blogSection, setBlogSection] = useState(null);

    const handleGetBlogSectionRequest = async () => {
        const getBlogSectionReq = await HomePageServices.getAuthBlogSection({
            token: user.access_token
        });

        if(getBlogSectionReq.status === false){
            ToastAlert.notifyError(getBlogSectionReq.message);
            setBlogSection(null);
        }
        else{
            console.log(getBlogSectionReq.data);
            setBlogSection(getBlogSectionReq.data);
        }
    }

    useEffect(() => {
        handleGetBlogSectionRequest();
    }, []);

    const [showUpdateBlogSectionHeadingsPopUp, setShowUpdateBlogSectionHeadingsPopUp] = useState(false);

    const handleConcludeUpdateBlogSectionHeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateBlogSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateBlogSectionHeadingsPopUp(false);
            handleGetBlogSectionRequest();
        }
    }

    const [showUpdateBlogActionPopUp, setShowUpdateBlogActionPopUp] = useState(false);

    const handleConcludeUpdateBlogActionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateBlogActionPopUp(false);
        }
        else{
            setShowUpdateBlogActionPopUp(false);
            handleGetBlogSectionRequest();
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Home Page'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to home page in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"SEO"} headingActionIcon={icons.addSolidCircle} headingAction={() => navigate("seo")} isBottomAction={false}>
                    {
                        seo === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No SEO Details found!"}
                                    description={"Update seo details by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => navigate("seo")}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetSEORequest("refresh")}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            {
                                seo?.title != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"title"} 
                                        description={defaults.capitalize(seo?.title)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.description != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"description"} 
                                        description={defaults.capitalize(seo?.description)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.keywords != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"keywords"} 
                                        description={defaults.capitalize(seo?.keywords)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.canonical_url != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"canonical URL"} 
                                        description={defaults.capitalize(seo?.canonical_url)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.twitter_handler != ""
                                ?
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"twitter handler"} 
                                        description={defaults.capitalize(seo?.twitter_handler)} 
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                            {
                                seo?.image != undefined
                                ?
                                <DetailsRow>
                                    <DetailsItemWithPreview 
                                        lable={`Image`}
                                        preview={`${globals.API_BASE_URL}${seo?.image?.image_url?.full?.high_res}`}
                                        previewType={"image"}
                                        fileName={seo?.image?.name?.original}
                                        fileExtension={seo?.image?.extension?.original}
                                        fileSize={seo?.image?.size?.current}
                                    />
                                </DetailsRow>
                                :
                                <></>
                            }
                        </PlainContainer>
                    }
                </Card>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Banners"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddBannerPopUp(true)} isBottomAction={false}>
                        {
                            banners.length === 0
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No Banners found!"}
                                        description={"Add new banner by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"add banner"}
                                        primaryAction={() => setShowAddBannerPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => handleGetBannersRequest("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <Table>
                                    <TableHeading>
                                        <TableHeadingItem size={4} lable={"name"} />
                                        <TableHeadingItem size={1} lable={"created by"} />
                                        <TableHeadingItem size={2} lable={"Actions"} />
                                    </TableHeading>

                                    <SortableBannerContainer onSortEnd={handleSortBannersEnd} useDragHandle>
                                        {banners.map((item, index) => (
                                            <SortableBannerItem key={`item-${index}`} index={index} value={item} />
                                        ))}
                                    </SortableBannerContainer>
                                </Table>
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Category Section"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateCategoryHeadingsPopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"description"} 
                                    description={defaults.capitalize(categorySection?.headings?.description)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"button lable"} 
                                    description={defaults.capitalize(categorySection?.headings?.button?.lable)} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"button action"} 
                                    description={defaults.capitalize(categorySection?.headings?.button?.action)} 
                                />
                            </DetailsRow>

                            <PlainContainer type='full' styles={{marginTop: "10px"}}>
                                <Card hasHeading={true} heading={"Categories List"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddCategoryItemPopUp(true)} isBottomAction={false}>
                                    <Table>
                                        <TableHeading>
                                            <TableHeadingItem size={4} lable={"name"} />
                                            <TableHeadingItem size={1} lable={"go to type"} />
                                            <TableHeadingItem size={2} lable={"go to"} />
                                            <TableHeadingItem size={1} lable={"created by"} />
                                            <TableHeadingItem size={2} lable={"Actions"} />
                                        </TableHeading>

                                        <SortableCategoryListContainer onSortEnd={handleSortCategoriesEnd} useDragHandle>
                                            {categorySection?.categories?.map((item, index) => (
                                                <SortableCategoryListItem key={`item-${index}`} index={index} value={item} />
                                            ))}
                                        </SortableCategoryListContainer>
                                    </Table>
                                </Card>
                            </PlainContainer>

                        </PlainContainer>
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"About Us Section"} headingActionIcon={icons.addSolidCircle} headingAction={() => console.log(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Section Headings"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateAboutSectionHeadingsPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"tagline"} 
                                            description={defaults.capitalize(aboutSection?.section_headings?.tagline)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"heading"} 
                                            description={defaults.capitalize(aboutSection?.section_headings?.heading)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"description"} 
                                            description={defaults.capitalize(aboutSection?.section_headings?.description)} 
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Cover Images"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateAboutCoverImagesPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Portrait Image`}
                                            preview={`${globals.API_BASE_URL}${aboutSection?.cover_images?.portrait?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={aboutSection?.cover_images?.portrait?.name?.original}
                                            fileExtension={aboutSection?.cover_images?.portrait?.extension?.original}
                                            fileSize={aboutSection?.cover_images?.portrait?.size?.current}
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Square Top Image`}
                                            preview={`${globals.API_BASE_URL}${aboutSection?.cover_images?.square_top?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={aboutSection?.cover_images?.square_top?.name?.original}
                                            fileExtension={aboutSection?.cover_images?.square_top?.extension?.original}
                                            fileSize={aboutSection?.cover_images?.square_top?.size?.current}
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Square Bottom Image`}
                                            preview={`${globals.API_BASE_URL}${aboutSection?.cover_images?.square_bottom?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={aboutSection?.cover_images?.square_bottom?.name?.original}
                                            fileExtension={aboutSection?.cover_images?.square_bottom?.extension?.original}
                                            fileSize={aboutSection?.cover_images?.square_bottom?.size?.current}
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Statistics"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateAboutStatsPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Stats 1 - Lable"} 
                                            description={defaults.capitalize(aboutSection?.statistics?.stat_1?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Stats 1 - Count"} 
                                            description={defaults.capitalize(aboutSection?.statistics?.stat_1?.count)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Stats 2 - Lable"} 
                                            description={defaults.capitalize(aboutSection?.statistics?.stat_2?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Stats 2 - Count"} 
                                            description={defaults.capitalize(aboutSection?.statistics?.stat_2?.count)} 
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Highlights"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddAboutHighlightPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <Table>
                                        <TableHeading>
                                            <TableHeadingItem size={4} lable={"lable"} />
                                            <TableHeadingItem size={1} lable={"created by"} />
                                            <TableHeadingItem size={2} lable={"Actions"} />
                                        </TableHeading>

                                        <SortableAboutHighlightContainer onSortEnd={handleAboutHighlightSortEnd} useDragHandle>
                                            {aboutSection?.highlights.map((item, index) => (
                                                <SortableAboutHighlightItem key={`item-${index}`} index={index} value={item} />
                                            ))}
                                        </SortableAboutHighlightContainer>
                                    </Table>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Actions"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateAboutActionPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Primary - Lable"} 
                                            description={defaults.capitalize(aboutSection?.actions?.primary?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Primary - Action"} 
                                            description={defaults.capitalize(aboutSection?.actions?.primary?.action)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Secondary - Lable"} 
                                            description={defaults.capitalize(aboutSection?.actions?.secondary?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Secondary - Value"} 
                                            description={defaults.capitalize(aboutSection?.actions?.secondary?.value)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItemWithPreview 
                                            lable={`Secondary Icon`}
                                            preview={`${globals.API_BASE_URL}${aboutSection?.actions?.secondary?.icon?.image_url?.full?.high_res}`}
                                            previewType={"image"}
                                            fileName={aboutSection?.actions?.secondary?.icon?.name?.original}
                                            fileExtension={aboutSection?.actions?.secondary?.icon?.extension?.original}
                                            fileSize={aboutSection?.actions?.secondary?.icon?.size?.current}
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Products Section"} headingActionIcon={icons.addSolidCircle} headingAction={() => console.log(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Section Headings"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateProductSectionHeadingsPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"tagline"} 
                                            description={defaults.capitalize(productSection?.section_headings?.tagline)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"heading"} 
                                            description={defaults.capitalize(productSection?.section_headings?.heading)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"description"} 
                                            description={defaults.capitalize(productSection?.section_headings?.description)} 
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Actions"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateProductActionPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Primary - Lable"} 
                                            description={defaults.capitalize(productSection?.actions?.primary?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Primary - Action"} 
                                            description={defaults.capitalize(productSection?.actions?.primary?.action)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Secondary - Lable"} 
                                            description={defaults.capitalize(productSection?.actions?.secondary?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Secondary - Action"} 
                                            description={defaults.capitalize(productSection?.actions?.secondary?.action)} 
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Blog Section"} headingActionIcon={icons.addSolidCircle} headingAction={() => console.log(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Section Headings"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateBlogSectionHeadingsPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"tagline"} 
                                            description={defaults.capitalize(blogSection?.section_headings?.tagline)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"heading"} 
                                            description={defaults.capitalize(blogSection?.section_headings?.heading)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"description"} 
                                            description={defaults.capitalize(blogSection?.section_headings?.description)} 
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>

                        <PlainContainer type='full' styles={{paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Card hasHeading={true} heading={"Actions"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateBlogActionPopUp(true)} isBottomAction={false}>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Primary - Lable"} 
                                            description={defaults.capitalize(blogSection?.actions?.primary?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Primary - Action"} 
                                            description={defaults.capitalize(blogSection?.actions?.primary?.action)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Secondary - Lable"} 
                                            description={defaults.capitalize(blogSection?.actions?.secondary?.lable)} 
                                        />
                                    </DetailsRow>
                                    <DetailsRow>
                                        <DetailsItem 
                                            hasIcon={false} 
                                            icon={``} 
                                            lable={"Secondary - Action"} 
                                            description={defaults.capitalize(blogSection?.actions?.secondary?.action)} 
                                        />
                                    </DetailsRow>
                                </PlainContainer>
                            </Card>
                        </PlainContainer>
                    </Card>
                </PlainContainer>
            </PlainContainer>

            {
                showAddBannerPopUp === true
                ?
                <PopUp width="max" heading="Add Banner" hasBottomPadding={true} closePopUp={() => handleConcludeAddBannerPopUp({status: false})}>
                    <AddBannerHomePagePopUp user={user} concludeAddBannerPopUp={(e) => handleConcludeAddBannerPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateCategoryHeadingsPopUp === true
                ?
                <PopUp width="max" heading="Add Banner" hasBottomPadding={true} closePopUp={() => handleConcludeUpdateCategorySectionHeadingsPopUp({status: false})}>
                    <UpdateHomePageCategorySectionHeadingsPopUp user={user} section={categorySection.headings} concludeUpdateCategorySectionHeadingsPopUp={(e) => handleConcludeUpdateCategorySectionHeadingsPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddCategoryItemPopUp === true
                ?
                <PopUp width="max" heading="Add Banner" hasBottomPadding={true} closePopUp={() => handleConcludeAddCategoryItemPopUp({status: false})}>
                    <AddCategoryHomePagePopUp user={user} concludeAddCategoryListItemPopUp={(e) => handleConcludeAddCategoryItemPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateAboutSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateAboutSectionHeadingPopUp({status: false})}>
                    <UpdateHomePageAboutSectionHeadingsPopUp user={user} section_headings={aboutSection?.section_headings} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateAboutSectionHeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateAboutCoverImagesPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Cover Images" closePopUp={() => handleConcludeUpdateAboutCoverImagesPopUp({status: false})}>
                    <UpdateHomePageAboutCoverImagesPopUp user={user} cover_images={aboutSection?.cover_images} concludeUpdateCoverImagesPopUp={(e) => handleConcludeUpdateAboutCoverImagesPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateAboutStatsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Stats" closePopUp={() => handleConcludeUpdateAboutStatsPopUp({status: false})}>
                    <UpdateHomePageAboutStatsPopUp user={user} stats={aboutSection?.statistics} concludeUpdateStatsPopUp={(e) => handleConcludeUpdateAboutStatsPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddAboutHighlightPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Highlight" closePopUp={() => handleConcludeAddAboutHighlightPopUp({status: false})}>
                    <AddHomePageAboutHighlightPopUp user={user} concludeAddHighlightPopUp={(e) => handleConcludeAddAboutHighlightPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateAboutHighlightPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Highlight" closePopUp={() => handleConcludeUpdateAboutHighlightPopUp({status: false})}>
                    <UpdateHomepageAboutHighlightPopUp user={user} highlight={aboutHighlightToUpdate} concludeUpdateHighlightPopUp={(e) => handleConcludeUpdateAboutHighlightPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateAboutActionPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Actions" closePopUp={() => handleConcludeUpdateAboutActionPopUp({status: false})}>
                    <UpdateHomePageAboutActionPopUp user={user} action={aboutSection?.actions} concludeUpdateActionPopUp={(e) => handleConcludeUpdateAboutActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateProductSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateProductSectionHeadingPopUp({status: false})}>
                    <UpdateHomePageProductSectionHeadingsPopUp user={user} section_headings={productSection?.section_headings} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateProductSectionHeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateProductActionPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Actions" closePopUp={() => handleConcludeUpdateProductActionPopUp({status: false})}>
                    <UpdateHomePageProductActionPopUp user={user} action={productSection?.actions} concludeUpdateActionPopUp={(e) => handleConcludeUpdateProductActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateBlogSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateBlogSectionHeadingPopUp({status: false})}>
                    <UpdateHomePageBlogSectionHeadingsPopUp user={user} section_headings={blogSection?.section_headings} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateBlogSectionHeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateBlogActionPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Actions" closePopUp={() => handleConcludeUpdateBlogActionPopUp({status: false})}>
                    <UpdateHomePageBlogActionPopUp user={user} action={blogSection?.actions} concludeUpdateActionPopUp={(e) => handleConcludeUpdateBlogActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }
        </>
    )
}

export default HomePage