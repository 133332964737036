import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
    LoginPage, 
    ForgotPasswordPage, 
    ChangePasswordPage, 
    ForgotPasswordNeedsApprovalPage, 
    VerifyForgotPasswordOTPPage, 
    ForgotPasswordOTPExpiredPage, 
    PasswordChangeSuccessPage, 
    ScanToEnable2FAPage, 
    VerifyEnable2FAOTPPage, 
    Verify2FAOTPPage, 
    RegisterSuperAdminPage, 
    ValidateEmailIDPage, 
    ValidatePhoneNumberPage, 
    ForgotPasswordReqAlreadyExistsPage, 
    ForgotPasswordRecentlyRejectedPage ,
    SuperAdminHomePage,
    AdminHomePage,
    CreatorHomePage,
    ModeratorHomePage,
    ApproverHomePage,
    ResetPasswordRequestWithdrawnPage,
    SuperAdminHomeWebsitesPage,
    SuperAdminFileManagerPage,
    SuperAdminAddFilesPage,
    SuperAdminAddAdminUserPage,
    SuperAdminAdminUsersPage,
    SuperAdminAvatarsListPage,
    SuperAdminAddAvatarPage,
    SuperAdminEditAvatarPage,
    StatesListPage,
    CitiesListPage,
    DistrictsListPage,
    AddStatePage,
    EditStatePage,
    AddDistrictPage,
    EditDistrictPage,
    AddCityPage,
    EditCityPage,
    MediaTypesListPage,
    AddMediaTypePage,
    EditMediaTypePage,
    HeaderPage,
    PagesListPage,
    AddPagesPage,
    WhyChooseUsPage,
    ClientsListPage,
    AddClientPage,
    TestimonialPage,
    RequestAQuotePage,
    FooterPage,
    HomePage,
    UpdateHomePageSEOPage,
    AboutUsPage,
    UpdateAboutUsPageSEOPage,
    UniformsPage,
    UpdateUniformsPageSEOPage,
    CorporateGiftingPage,
    UpdateCorporateGiftingPageSEOPage,
    ClientelePage,
    UpdateClientelePageSEOPage,
    BlogPage,
    UpdateBlogPageSEOPage,
    ContactUsPage,
    UpdateContactUsPageSEOPage
} from '../pages';
import PreLoginOutlet from './LoginOutlet';
import { defaults, verifyToken } from '../utils';
import DefaultOutlet from './DefaultOutlet';
import DashboardOutlet from './DashboardOutlet';

const AppRouter = ({user}) => {

    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isValidated, setIsValidated] = useState(false);
    const [toBeValidated, setToBeValidated] = useState("");
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    
    const isSuperAdmin = defaults.ROLES.SUPER_ADMIN === user.role;
    const isAdmin = defaults.ROLES.ADMIN === user.role;
    const isCreator = defaults.ROLES.CREATOR === user.role;
    const isModerator = defaults.ROLES.MODERATOR === user.role;
    const isApprover = defaults.ROLES.APPROVER === user.role;
  
    useEffect(() => {
        checkIsLoggedIn();
    }, [user]);

    const checkIsLoggedIn = async () => {
        if(user.is_logged_in === true){
            const checkToken = await verifyToken(user.access_token);
            setIsLoggedIn(checkToken.status);
            setIsValidated(user.is_validated);
            setToBeValidated(user.to_be_validated);
            setIs2FAEnabled(user.is_2fa_enabled);
        }
        else{
            setIsLoggedIn(false);
            setIsValidated(user.is_validated);
            setToBeValidated(user.to_be_validated);
            setIs2FAEnabled(user.is_2fa_enabled);
        }
    }

    return (
        <>
            {
                isLoggedIn != null
                ?
                    <Routes>
                        {
                            isLoggedIn === false
                            ?
                            <Route path="/" element={<PreLoginOutlet user={user} />}>
                                <Route index element={<LoginPage />} />
                                <Route path="forgot-password" element={
                                    user.forgot_password_current_route === "FORGOT_PASSWORD" 
                                    ?
                                    <ForgotPasswordPage user={user} />
                                    :
                                    user.forgot_password_current_route === "CHANGE_PASSWORD" 
                                    ?
                                    <ChangePasswordPage user={user} />
                                    :
                                    user.forgot_password_current_route === "ALREADY_EXIST"
                                    ?
                                    <ForgotPasswordReqAlreadyExistsPage user={user} />
                                    :
                                    user.forgot_password_current_route === "VALIDATE_OTP"
                                    ?
                                    <VerifyForgotPasswordOTPPage user={user} />
                                    :
                                    user.forgot_password_current_route === "REQUEST_WITHDRAWN"
                                    ?
                                    <ResetPasswordRequestWithdrawnPage user={user} />
                                    :
                                    user.forgot_password_current_route === "TRY_LATER"
                                    ?
                                    <ForgotPasswordRecentlyRejectedPage user={user} />
                                    :
                                    user.forgot_password_current_route === "REQUIRES_APPROVAL"
                                    ?
                                    <ForgotPasswordNeedsApprovalPage user={user} />
                                    :
                                    <ForgotPasswordPage user={user} />
                                } />
                                <Route path="password-reset-expired-otp" element={<ForgotPasswordOTPExpiredPage />} />
                                <Route path="password-change-success" element={<PasswordChangeSuccessPage />} />
                                <Route path="register" element={<RegisterSuperAdminPage />} />
                            </Route>
                            :
                            isLoggedIn === true && isValidated === false
                            ?
                                toBeValidated === "email"
                                ?
                                    <Route path="/" element={<PreLoginOutlet user={user} />}>
                                        <Route index element={<ValidateEmailIDPage user={user} />} />
                                    </Route>
                                :
                                toBeValidated === "phone"
                                ?
                                    <Route path="/" element={<PreLoginOutlet user={user} />}>
                                        <Route index element={<ValidatePhoneNumberPage user={user} />} />
                                    </Route>
                                :
                                toBeValidated === "2fa"
                                ?
                                    is2FAEnabled === true
                                    ?
                                        <Route path="/" element={<PreLoginOutlet user={user} />}>
                                            <Route index element={<Verify2FAOTPPage user={user} />} />
                                            <Route path="/enable-2fa" element={<ScanToEnable2FAPage user={user} />} />
                                        </Route>
                                    :
                                        <Route path="/" element={<PreLoginOutlet user={user} />}>
                                            <Route index element={<ScanToEnable2FAPage user={user} />} />
                                            <Route path="/verify-enable-2fa-otp" element={<VerifyEnable2FAOTPPage user={user} />} />
                                        </Route>
                                :
                                <></>
                            :
                            isLoggedIn === true && isValidated === true
                            ?
                                <>
                                    <Route path="/" element={<Navigate to={"/dashboard/home"} />} />
                                    <Route path="/dashboard" element={<DashboardOutlet user={user} />}>
                                        <Route index element={<Navigate to={"/dashboard/home"} />} />
                                        <Route path={"home"} element={
                                            isSuperAdmin ? <SuperAdminHomePage user={user} /> : 
                                            isAdmin ? <AdminHomePage /> : 
                                            isCreator ? <CreatorHomePage /> : 
                                            isModerator ? <ModeratorHomePage /> : 
                                            isApprover ? <ApproverHomePage /> : 
                                            <Navigate to="/404" /> } 
                                        />
                                    </Route>
                                    <Route path="/general" element={<DashboardOutlet user={user} />}>
                                        <Route path={"file-manager"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <SuperAdminFileManagerPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"upload"} element={
                                                isSuperAdmin ? <SuperAdminAddFilesPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"users"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <Navigate to={"/general/users/admins"} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"admins"} element={<DefaultOutlet />}>
                                                <Route index element={
                                                    isSuperAdmin ? <SuperAdminAdminUsersPage user={user} /> : 
                                                    // isAdmin ? <AdminHomePage /> : 
                                                    // isCreator ? <CreatorHomePage /> : 
                                                    // isModerator ? <ModeratorHomePage /> : 
                                                    // isApprover ? <ApproverHomePage /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"add"} element={
                                                    isSuperAdmin ? <SuperAdminAddAdminUserPage user={user} /> : 
                                                    // isAdmin ? <AdminHomePage /> : 
                                                    // isCreator ? <CreatorHomePage /> : 
                                                    // isModerator ? <ModeratorHomePage /> : 
                                                    // isApprover ? <ApproverHomePage /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                            </Route>
                                        </Route>
                                        <Route path={"pages"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <PagesListPage user={user} /> : 
                                                isAdmin ? <PagesListPage user={user} /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isSuperAdmin ? <AddPagesPage user={user} /> : 
                                                isAdmin ? <AddPagesPage user={user} /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                    </Route>
                                    <Route path={"masterdata"} element={<DashboardOutlet user={user} />}>
                                        <Route index element={
                                            isSuperAdmin ? <Navigate to={"/dashboard/home"} /> : 
                                            // isAdmin ? <AdminHomePage /> : 
                                            // isCreator ? <CreatorHomePage /> : 
                                            // isModerator ? <ModeratorHomePage /> : 
                                            // isApprover ? <ApproverHomePage /> : 
                                            <Navigate to="/404" /> } 
                                        />
                                        <Route path={"media-types"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <MediaTypesListPage user={user} /> : 
                                                isAdmin ? <MediaTypesListPage user={user} /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isSuperAdmin ? <AddMediaTypePage user={user} /> : 
                                                isAdmin ? <AddMediaTypePage user={user} /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"avatars"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <SuperAdminAvatarsListPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isSuperAdmin ? <SuperAdminAddAvatarPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"edit:id"} element={
                                                isSuperAdmin ? <SuperAdminEditAvatarPage user={user} /> : 
                                                // isAdmin ? <AdminHomePage /> : 
                                                // isCreator ? <CreatorHomePage /> : 
                                                // isModerator ? <ModeratorHomePage /> : 
                                                // isApprover ? <ApproverHomePage /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"locations"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <Navigate to={"/masterdata/locations/states"} /> : 
                                                isAdmin ? <Navigate to={"/masterdata/locations/states"} /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"states"} element={<DefaultOutlet />}>
                                                <Route index element={
                                                    isSuperAdmin ? <StatesListPage user={user} /> : 
                                                    isAdmin ? <StatesListPage user={user} /> :
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"add"} element={
                                                    isSuperAdmin ? <AddStatePage user={user} /> : 
                                                    isAdmin ? <AddStatePage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"edit/:id"} element={
                                                    isSuperAdmin ? <EditStatePage user={user} /> : 
                                                    isAdmin ? <EditStatePage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                            </Route>
                                            <Route path={"districts"} element={<DefaultOutlet />}>
                                                <Route index element={
                                                    isSuperAdmin ? <DistrictsListPage user={user} /> : 
                                                    isAdmin ? <DistrictsListPage user={user} /> :
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"add"} element={
                                                    isSuperAdmin ? <AddDistrictPage user={user} /> : 
                                                    isAdmin ? <AddDistrictPage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"edit/:id"} element={
                                                    isSuperAdmin ? <EditDistrictPage user={user} /> : 
                                                    isAdmin ? <EditDistrictPage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                            </Route>
                                            <Route path={"cities"} element={<DefaultOutlet />}>
                                                <Route index element={
                                                    isSuperAdmin ? <CitiesListPage user={user} /> : 
                                                    isAdmin ? <CitiesListPage user={user} /> :
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"add"} element={
                                                    isSuperAdmin ? <AddCityPage user={user} /> : 
                                                    isAdmin ? <AddCityPage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                                <Route path={"edit/:id"} element={
                                                    isSuperAdmin ? <EditCityPage user={user} /> : 
                                                    isAdmin ? <EditCityPage user={user} /> : 
                                                    <Navigate to="/404" /> } 
                                                />
                                            </Route>
                                        </Route>
                                    </Route>
                                    <Route path={"common"} element={<DashboardOutlet user={user} />}>
                                        {/* <Route index element={<Navigate to={"/common/header"} />} /> */}
                                        <Route index element={
                                            isSuperAdmin ? <Navigate to={"/dashboard/home"} /> : 
                                            isAdmin ? <Navigate to={"/dashboard/home"} /> : 
                                            <Navigate to="/404" /> } 
                                        />
                                        <Route path={"header"} element={
                                            isSuperAdmin ? <HeaderPage user={user} /> : 
                                            isAdmin ? <HeaderPage user={user} /> : 
                                            <Navigate to="/404" /> } 
                                        />
                                        <Route path={"why-choose-us"} element={
                                            isSuperAdmin ? <WhyChooseUsPage user={user} /> : 
                                            isAdmin ? <WhyChooseUsPage user={user} /> : 
                                            <Navigate to="/404" /> } 
                                        />
                                        <Route path={"clients"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <ClientsListPage user={user} /> : 
                                                isAdmin ? <ClientsListPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"add"} element={
                                                isSuperAdmin ? <AddClientPage user={user} /> : 
                                                isAdmin ? <AddClientPage user={user} /> : 
                                                <Navigate to="/404" /> } 
                                            />
                                            {/* <Route path={"edit/:id"} element={
                                                isSuperAdmin ? <EditCityPage user={user} /> : 
                                                isAdmin ? <EditCityPage user={user} /> : 
                                                <Navigate to="/404" /> } 
                                            /> */}
                                        </Route>
                                        <Route path={"testimonials"} element={
                                            isSuperAdmin ? <TestimonialPage user={user} /> : 
                                            isAdmin ? <TestimonialPage user={user} /> : 
                                            <Navigate to="/404" /> } 
                                        />
                                        <Route path={"request-a-quote"} element={
                                            isSuperAdmin ? <RequestAQuotePage user={user} /> : 
                                            isAdmin ? <RequestAQuotePage user={user} /> : 
                                            <Navigate to="/404" /> } 
                                        />
                                        <Route path={"footer"} element={
                                            isSuperAdmin ? <FooterPage user={user} /> : 
                                            isAdmin ? <FooterPage user={user} /> : 
                                            <Navigate to="/404" /> } 
                                        />
                                    </Route>
                                    <Route path={"page"} element={<DashboardOutlet user={user} />}>
                                        {/* <Route index element={<Navigate to={"/common/header"} />} /> */}
                                        <Route path={"home"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <HomePage user={user} /> : 
                                                isAdmin ? <HomePage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"seo"} element={
                                                isSuperAdmin ? <UpdateHomePageSEOPage user={user} /> : 
                                                isAdmin ? <UpdateHomePageSEOPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"about-us"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <AboutUsPage user={user} /> : 
                                                isAdmin ? <AboutUsPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"seo"} element={
                                                isSuperAdmin ? <UpdateAboutUsPageSEOPage user={user} /> : 
                                                isAdmin ? <UpdateAboutUsPageSEOPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"uniforms"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <UniformsPage user={user} /> : 
                                                isAdmin ? <UniformsPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"seo"} element={
                                                isSuperAdmin ? <UpdateUniformsPageSEOPage user={user} /> : 
                                                isAdmin ? <UpdateUniformsPageSEOPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"corporate-gifting"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <CorporateGiftingPage user={user} /> : 
                                                isAdmin ? <CorporateGiftingPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"seo"} element={
                                                isSuperAdmin ? <UpdateCorporateGiftingPageSEOPage user={user} /> : 
                                                isAdmin ? <UpdateCorporateGiftingPageSEOPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"clientele"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <ClientelePage user={user} /> : 
                                                isAdmin ? <ClientelePage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"seo"} element={
                                                isSuperAdmin ? <UpdateClientelePageSEOPage user={user} /> : 
                                                isAdmin ? <UpdateClientelePageSEOPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"blog"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <BlogPage user={user} /> : 
                                                isAdmin ? <BlogPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"seo"} element={
                                                isSuperAdmin ? <UpdateBlogPageSEOPage user={user} /> : 
                                                isAdmin ? <UpdateBlogPageSEOPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                        <Route path={"contact-us"} element={<DefaultOutlet />}>
                                            <Route index element={
                                                isSuperAdmin ? <ContactUsPage user={user} /> : 
                                                isAdmin ? <ContactUsPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                            <Route path={"seo"} element={
                                                isSuperAdmin ? <UpdateContactUsPageSEOPage user={user} /> : 
                                                isAdmin ? <UpdateContactUsPageSEOPage user={user} /> :
                                                <Navigate to="/404" /> } 
                                            />
                                        </Route>
                                    </Route>
                                </>
                            :
                            <></>
                        }
                        {/* <Route path="/404" element={<div>404</div>} /> */}
                        <Route path="*" element={<Navigate to={isLoggedIn === true && isValidated === true ? "/dashboard/home" : "/"} />} />
                    </Routes>
                :
                    <></>
            }
        </>
    )
}

export default AppRouter