import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT'
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { FooterServices } from '../../../../services';
import TextAreaInput from '../../../core/form/TextAreaInput';

const UpdateFooterContactDetailsPopUp = ({ user, contact_us, concludeUpdateContactDetailsPopUp }) => {

    const [address, setAddress] = useState(contact_us != null ? contact_us.address : "");
    const [hasAddressMessage, setHasAddressMessage] = useState(false);
    const [addressMessage, setAddressMessage] = useState("");
    const [addressMessageType, setAddressMessageType] = useState("");

    const [phoneNumber, setPhoneNumber] = useState(contact_us != null ? contact_us.phone_number : "");
    const [hasPhoneNumberMessage, setHasPhoneNumberMessage] = useState(false);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState("");
    const [phoneNumberMessageType, setPhoneNumberMessageType] = useState("");

    const [emailID, setEmailID] = useState(contact_us != null ? contact_us.email_id : "");
    const [hasEmailIDMessage, setHasEmailIDMessage] = useState(false);
    const [emailIDMessage, setEmailIDMessage] = useState("");
    const [emailIDMessageType, setEmailIDMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateContactDetailsSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(address.trim() === ""){
            setHasAddressMessage(true);
            setAddressMessage("Invalid Address");
            setAddressMessageType("error");
        }
        else{
            setHasAddressMessage(false);
            setAddressMessage("");
            setAddressMessageType("");
        }

        if(phoneNumber.trim() === ""){
            setHasPhoneNumberMessage(true);
            setPhoneNumberMessage("Invalid Phone Number");
            setPhoneNumberMessageType("error");
        }
        else{
            setHasPhoneNumberMessage(false);
            setPhoneNumberMessage("");
            setPhoneNumberMessageType("");
        }

        if(emailID.trim() === ""){
            setHasEmailIDMessage(true);
            setEmailIDMessage("Invalid Email ID");
            setEmailIDMessageType("error");
        }
        else{
            setHasEmailIDMessage(false);
            setEmailIDMessage("");
            setEmailIDMessageType("");
        }

        if(address.trim() === "" || phoneNumber.trim() === "" || emailID.trim() === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveContactDetailsReq = await FooterServices.updateContactDetails({
                address: address,
                phone_number: phoneNumber,
                email_id: emailID,
                token: user.access_token
            });

            if(saveContactDetailsReq.status === false){
                ToastAlert.notifyError(saveContactDetailsReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveContactDetailsReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateContactDetailsPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateContactDetailsSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.footerContactUsAddress.hasLable}
                        lable={inputs.footerContactUsAddress.lable}
                        isMandatory={inputs.footerContactUsAddress.is_mandatory}
                        hasMessage={hasAddressMessage}
                        message={addressMessage}
                        messageType={addressMessageType}
                        isLimited={inputs.footerContactUsAddress.is_limited}
                        limit={inputs.footerContactUsAddress.max_characters}
                        value={address}
                    >
                        <input 
                            type={inputs.footerContactUsAddress.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerContactUsAddress.placeholder}
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.footerContactUsPhoneNumber.hasLable}
                        lable={inputs.footerContactUsPhoneNumber.lable}
                        isMandatory={inputs.footerContactUsPhoneNumber.is_mandatory}
                        hasMessage={hasPhoneNumberMessage}
                        message={phoneNumberMessage}
                        messageType={phoneNumberMessageType}
                        isLimited={inputs.footerContactUsPhoneNumber.is_limited}
                        limit={inputs.footerContactUsPhoneNumber.max_characters}
                        value={phoneNumber}
                    >
                        <input 
                            type={inputs.footerContactUsPhoneNumber.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerContactUsPhoneNumber.placeholder}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.footerContactUsEmailID.hasLable}
                        lable={inputs.footerContactUsEmailID.lable}
                        isMandatory={inputs.footerContactUsEmailID.is_mandatory}
                        hasMessage={hasEmailIDMessage}
                        message={emailIDMessage}
                        messageType={emailIDMessageType}
                        isLimited={inputs.footerContactUsEmailID.is_limited}
                        limit={inputs.footerContactUsEmailID.max_characters}
                        value={emailID}
                    >
                        <input 
                            type={inputs.footerContactUsEmailID.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerContactUsEmailID.placeholder}
                            onChange={(e) => setEmailID(e.target.value)}
                            value={emailID}
                        />
                    </TextInputRT>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateContactDetailsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateFooterContactDetailsPopUp