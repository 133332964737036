import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import { HomePageServices } from '../../../../../services';

const AddBannerHomePagePopUp = ({ user, concludeAddBannerPopUp }) => {

    const [name, setName] = useState("");
    const [hasNameMessage, setHasNameMessage] = useState(false);
    const [nameMessage, setNameMessage] = useState("");
    const [nameMessageType, setNameMessageType] = useState("");

    const [action, setAction] = useState("");
    const [hasActionMessage, setHasActionMessage] = useState(false);
    const [actionMessage, setActionMessage] = useState("");
    const [actionMessageType, setActionMessageType] = useState("");

    const [selectedWebImages, setSelectedWebImages] = useState([]);
    const [webImageType, setWebImageType] = useState("image");
    const [webImageIsMultiple, setWebImageIsMultiple] = useState(false);
    const [webImageMaxFileSelection, setWebImageMaxFileSelection] = useState(1);
    const [webImagePopUpLable, setWebImagePopUpLable] = useState("Web Image");
    const [webImageIsError, setWebImageIsError] = useState(false);
    const [webImageData, setWebImageData] = useState(null);

    const [selectedMobileImages, setSelectedMobileImages] = useState([]);
    const [mobileImageType, setMobileImageType] = useState("image");
    const [mobileImageIsMultiple, setMobileImageIsMultiple] = useState(false);
    const [mobileImageMaxFileSelection, setMobileImageMaxFileSelection] = useState(1);
    const [mobileImagePopUpLable, setMobileImagePopUpLable] = useState("Mobile Image");
    const [mobileImageIsError, setMobileImageIsError] = useState(false);
    const [mobileImageData, setMobileImageData] = useState(null);

    const handleWebImageSelection = async (e) => {
        if(e.status === true){
            setSelectedWebImages([...e.files]);
            setWebImageIsError(false);
        }
        else{
            setSelectedWebImages([]);
        }
    }

    const handleMobileImageSelection = async (e) => {
        if(e.status === true){
            setSelectedMobileImages([...e.files]);
            setMobileImageIsError(false);
        }
        else{
            setSelectedMobileImages([]);
        }
    }

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleAddBannerSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(name.trim() === ""){
            setHasNameMessage(true);
            setNameMessage("Invalid Name");
            setNameMessageType("error");
        }
        else{
            setHasNameMessage(false);
            setNameMessage("");
            setNameMessageType("");
        }

        if(selectedWebImages.length === 0){
            setWebImageIsError(true);
        }
        else{
            setWebImageIsError(false);
        }

        if(selectedMobileImages.length === 0){
            setMobileImageIsError(true);
        }
        else{
            setMobileImageIsError(false);
        }

        if(name.trim() === "" || selectedWebImages.length === 0 || selectedMobileImages.length === 0){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveBannerReq = await HomePageServices.saveBanner({
                name: name,
                action: action,
                image_web: selectedWebImages[0],
                image_mobile: selectedMobileImages[0],
                token: user.access_token
            });

            if(saveBannerReq.status === false){
                ToastAlert.notifyError(saveBannerReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveBannerReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeAddBannerPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleAddBannerSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.addHomePageBannerName.hasLable}
                        lable={inputs.addHomePageBannerName.lable}
                        isMandatory={inputs.addHomePageBannerName.is_mandatory}
                        hasMessage={hasNameMessage}
                        message={nameMessage}
                        messageType={nameMessageType}
                        isLimited={inputs.addHomePageBannerName.is_limited}
                        limit={inputs.addHomePageBannerName.max_characters}
                        value={name}
                    >
                        <input 
                            type={inputs.addHomePageBannerName.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.addHomePageBannerName.placeholder}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.addHomePageBannerAction.hasLable}
                        lable={inputs.addHomePageBannerAction.lable}
                        isMandatory={inputs.addHomePageBannerAction.is_mandatory}
                        hasMessage={hasActionMessage}
                        message={actionMessage}
                        messageType={actionMessageType}
                        isLimited={inputs.addHomePageBannerAction.is_limited}
                        limit={inputs.addHomePageBannerAction.max_characters}
                        value={action}
                    >
                        <input 
                            type={inputs.addHomePageBannerAction.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.addHomePageBannerAction.placeholder}
                            onChange={(e) => setAction(e.target.value)}
                            value={action}
                        />
                    </TextInputRT>

                    <PlainContainer type="full">
                        <SplitContainer>
                            <FileUploadInput 
                                data={webImageData}
                                user={user} 
                                lable={webImagePopUpLable} 
                                acceptedFileType={webImageType} 
                                isPreview={false}
                                previewType={"image"}
                                preview={""}
                                isMultiple={webImageIsMultiple} 
                                maxSelection={webImageMaxFileSelection} 
                                isError={webImageIsError}
                                action={(e) => handleWebImageSelection(e)} 
                            />
                            <FileUploadInput 
                                data={mobileImageData}
                                user={user} 
                                lable={mobileImagePopUpLable} 
                                acceptedFileType={mobileImageType} 
                                isPreview={false}
                                previewType={"image"}
                                preview={""}
                                isMultiple={mobileImageIsMultiple} 
                                maxSelection={mobileImageMaxFileSelection} 
                                isError={mobileImageIsError}
                                action={(e) => handleMobileImageSelection(e)} 
                            />
                        </SplitContainer>
                    </PlainContainer>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeAddBannerPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default AddBannerHomePagePopUp