import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { WhyChooseUsServices } from '../../../services';
import PopUp from '../../../components/core/popup';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';
import UpdateWhyChooseUsSectionHeadingsPopUp from '../../../components/dashboard/popups/WhyChooseUs/UpdateWhyChooseUsSectionHeadingPopUp';
import AddWhyChooseUsHighlightPopUp from '../../../components/dashboard/popups/WhyChooseUs/AddWhyChooseUsHighlightPopUp';
import UpdateWhyChooseUsHighlightPopUp from '../../../components/dashboard/popups/WhyChooseUs/UpdateWhyChooseUsHighlightPopUp';

const WhyChooseUsPage = ({ user }) => {
    const navigate = useNavigate();

    const [sectionHeadings, setSectionHeadings] = useState(null);

    const [showUpdateSectionHeadingsPopUp, setShowUpdateSectionHeadingsPopUp] = useState(false);

    const handleGetSectionHeadingsRequest = async () => {
        const getSectionHeadingsReq = await WhyChooseUsServices.getAuthSectionHeadings({
            token: user.access_token
        });

        if(getSectionHeadingsReq.status === false){
            ToastAlert.notifyError(getSectionHeadingsReq.message);
            setSectionHeadings(null);
        }
        else{
            console.log(getSectionHeadingsReq.data);
            setSectionHeadings(getSectionHeadingsReq.data);
        }
    }

    useEffect(() => {
        handleGetSectionHeadingsRequest();
    }, []);

    const handleConcludeUpdateSectionHeadingPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateSectionHeadingsPopUp(false);
        }
        else{
            setShowUpdateSectionHeadingsPopUp(false);
            handleGetSectionHeadingsRequest();
        }
    }

    const [highlights, setHighlights] = useState([]);

    const handleGetHighlightsRequest = async () => {
        const getHighlightsReq = await WhyChooseUsServices.getAuthHighlights({
            token: user.access_token
        });

        if(getHighlightsReq.status === false){
            ToastAlert.notifyError(getHighlightsReq.message);
            setHighlights(null);
        }
        else{
            console.log(getHighlightsReq.data);
            setHighlights(getHighlightsReq.data);
        }
    }

    useEffect(() => {
        handleGetHighlightsRequest();
    }, []);

    const [showAddHighlightPopUp, setShowAddHighlightPopUp] = useState(false);

    const handleConcludeAddHighlightPopUp = async (e) => {
        if(e.status === false){
            setShowAddHighlightPopUp(false);
        }
        else{
            setShowAddHighlightPopUp(false);
            handleGetHighlightsRequest();
        }
    }

    const handleSaveSortedList = async (list) => {

        const sortHighlghtsReq = await WhyChooseUsServices.sortHighlights({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortHighlghtsReq.status === false){
            ToastAlert.notifyError(sortHighlghtsReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortHighlghtsReq.message);
            handleGetHighlightsRequest();
        }
    }

    const handleSortEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(highlights, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedList(entityIdWithIndex);

        setHighlights([...arrayMove]);
    }

    const [showUpdateHighlightPopUp, setShowUpdateHighlightPopUp] = useState(false);
    const [highlightToUpdate, setHighlightToUpdate] = useState(null);

    const handleShowUpdateHighlightPopUp = async (data) => {
        setHighlightToUpdate(data);
        setShowUpdateHighlightPopUp(true);
    }

    const handleConcludeUpdateHighlightPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateHighlightPopUp(false);
        }
        else{
            setShowUpdateHighlightPopUp(false);
            handleGetHighlightsRequest();
        }
    }

    const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

    const SortableItem = sortableElement(({value, index}) => (
        <TableRow key={index}>
            <DragHandle />
            <TableRowItem size={4} isCenter={false} hasPreview={true} previewType={'image'} previewItem={`${globals.API_BASE_URL}${value.icon.image_url.thumbnail.low_res}`} lable={value.lable} />
            <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
            <TableRowItem size={2} isCenter={false} hasChildren={true}>
                <TableRowActions>
                    <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleShowUpdateHighlightPopUp(value)}>edit</TableButton>
                    <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(value.id)}>delete</TableButton>
                </TableRowActions>
            </TableRowItem>
        </TableRow>
    ));
    
    const SortableContainer = sortableContainer(({children}) => {
        return <TableRows>{children}</TableRows>;
    });

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Why Choose Us'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to why choose us in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"Section Headings & Cover"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateSectionHeadingsPopUp(true)} isBottomAction={false}>
                    
                    {
                        sectionHeadings === null
                        ?
                        <>
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <NoDataContainer
                                    icon={icons.sad}
                                    heading={"No section headings found!"}
                                    description={"Update section headings by clicking on the below button."}
                                    hasSectionAction={false}
                                    sectionAction={() => alert("section add")}
                                    hasActions={true}
                                    hasPrimaryAction={true}
                                    primaryActionLable={"update"}
                                    primaryAction={() => setShowUpdateSectionHeadingsPopUp(true)}
                                    hasSecondaryAction={true}
                                    secondaryActionLable={"refresh"}
                                    secondaryAction={() => handleGetSectionHeadingsRequest()}
                                />
                            </PlainContainer>
                        </>
                        :
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"section tagline"} 
                                    description={sectionHeadings?.tagline} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"section heading"} 
                                    description={sectionHeadings?.heading} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItem 
                                    hasIcon={false} 
                                    icon={``} 
                                    lable={"section description"} 
                                    description={sectionHeadings?.description} 
                                />
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsItemWithPreview 
                                    lable={`Cover Image`}
                                    preview={`${globals.API_BASE_URL}${sectionHeadings?.cover?.image_url?.full?.high_res}`}
                                    previewType={"image"}
                                    fileName={sectionHeadings?.cover?.name?.original}
                                    fileExtension={sectionHeadings?.cover?.extension?.original}
                                    fileSize={sectionHeadings?.cover?.size?.current}
                                />
                            </DetailsRow>
                        </PlainContainer>
                    }
                </Card> 

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Highlights"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddHighlightPopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={4} lable={"lable"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableContainer onSortEnd={handleSortEnd} useDragHandle>
                                    {highlights.map((item, index) => (
                                        <SortableItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>
            </PlainContainer>

            {
                showAddHighlightPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Add New Highlight" closePopUp={() => handleConcludeAddHighlightPopUp({status: false})}>
                    <AddWhyChooseUsHighlightPopUp user={user} concludeAddHighlightPopUp={(e) => handleConcludeAddHighlightPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateHighlightPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Highlight" closePopUp={() => handleConcludeUpdateHighlightPopUp({status: false})}>
                    <UpdateWhyChooseUsHighlightPopUp user={user} highlight={highlightToUpdate} concludeUpdateHighlightPopUp={(e) => handleConcludeUpdateHighlightPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateSectionHeadingsPopUp === true
                ?
                <PopUp width="max" hasBottomPadding={true} heading="Update Section Headings" closePopUp={() => handleConcludeUpdateSectionHeadingPopUp({status: false})}>
                    <UpdateWhyChooseUsSectionHeadingsPopUp user={user} section_headings={sectionHeadings} concludeUpdateSectionHeadingsPopUp={(e) => handleConcludeUpdateSectionHeadingPopUp(e)} />
                </PopUp>
                :
                <></>
            }
        </>
    )
}

export default WhyChooseUsPage