import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT'
import FileUploadInput from '../../../core/form/FileUploadInput';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { HeaderServices } from '../../../../services';

const UpdateHeaderActionPopUp = ({ user, header_action, concludeUpdateHeaderActionPopUp }) => {

    const [lable, setLable] = useState(header_action != null ? header_action.lable : "");
    const [hasLableMessage, setHasLableMessage] = useState(false);
    const [lableMessage, setLableMessage] = useState("");
    const [lableMessageType, setLableMessageType] = useState("");

    const [value, setValue] = useState(header_action != null ? header_action.value : "");
    const [hasValueMessage, setHasValueMessage] = useState(false);
    const [valueMessage, setValueMessage] = useState("");
    const [valueMessageType, setValueMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateHeaderActionSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(lable.trim() === ""){
            setHasLableMessage(true);
            setLableMessage("Invalid Lable");
            setLableMessageType("error");
        }
        else{
            setHasLableMessage(false);
            setLableMessage("");
            setLableMessageType("");
        }

        if(value.trim() === ""){
            setHasValueMessage(true);
            setValueMessage("Invalid Value");
            setValueMessageType("error");
        }
        else{
            setHasValueMessage(false);
            setValueMessage("");
            setValueMessageType("");
        }

        if(header_action === null){
            if(selectedImages.length === 0){
                setImageIsError(true);
            }
            else{
                setImageIsError(false);
            }

            if(lable.trim() === "" || value.trim() === "" || selectedImages.length === 0){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const saveHeaderActionReq = await HeaderServices.saveHeaderAction({
                    lable: lable,
                    value: value,
                    image: selectedImages[0],
                    token: user.access_token
                });
    
                if(saveHeaderActionReq.status === false){
                    ToastAlert.notifyError(saveHeaderActionReq.message);
                    setSubmitButtonLoading(false);
                }
                else{
                    ToastAlert.notifySuccess(saveHeaderActionReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonLable('Success');
                    concludeUpdateHeaderActionPopUp({
                        status: true
                    })
                }
            }
        }
        else{
            if(lable.trim() === "" || value.trim() === ""){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const saveHeaderActionReq = await HeaderServices.saveHeaderAction({
                    lable: lable,
                    value: value,
                    image: selectedImages.length > 0 ? selectedImages[0] : header_action.image.id,
                    token: user.access_token
                });
    
                if(saveHeaderActionReq.status === false){
                    ToastAlert.notifyError(saveHeaderActionReq.message);
                    setSubmitButtonLoading(false);
                }
                else{
                    ToastAlert.notifySuccess(saveHeaderActionReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonLable('Success');
                    concludeUpdateHeaderActionPopUp({
                        status: true
                    })
                }
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateHeaderActionSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.headerActionLable.hasLable}
                        lable={inputs.headerActionLable.lable}
                        isMandatory={inputs.headerActionLable.is_mandatory}
                        hasMessage={hasLableMessage}
                        message={lableMessage}
                        messageType={lableMessageType}
                        isLimited={inputs.headerActionLable.is_limited}
                        limit={inputs.headerActionLable.max_characters}
                        value={lable}
                    >
                        <input 
                            type={inputs.headerActionLable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.headerActionLable.placeholder}
                            onChange={(e) => setLable(e.target.value)}
                            value={lable}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.headerActionValue.hasLable}
                        lable={inputs.headerActionValue.lable}
                        isMandatory={inputs.headerActionValue.is_mandatory}
                        hasMessage={hasValueMessage}
                        message={valueMessage}
                        messageType={valueMessageType}
                        isLimited={inputs.headerActionValue.is_limited}
                        limit={inputs.headerActionValue.max_characters}
                        value={value}
                    >
                        <input 
                            type={inputs.headerActionValue.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.headerActionValue.placeholder}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                    </TextInputRT>

                    <FileUploadInput 
                        data={imageData}
                        user={user} 
                        lable={imagePopUpLable} 
                        acceptedFileType={imageType} 
                        isPreview={header_action === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${header_action?.image?.image_url?.full?.high_res}`}
                        isMultiple={imageIsMultiple} 
                        maxSelection={imageMaxFileSelection} 
                        isError={imageIsError}
                        action={(e) => handleImageSelection(e)} 
                    />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateHeaderActionPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateHeaderActionPopUp