import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import { AboutPageServices } from '../../../../../services';

const UpdateAboutUsPageAboutStatsPopUp = ({ user, stats, concludeUpdateStatsPopUp }) => {
    
    const [stat1Lable, setStat1Lable] = useState(stats != null ? stats.stat_1.lable : "");
    const [hasStat1LableMessage, setHasStat1LableMessage] = useState(false);
    const [stat1LableMessage, setStat1LableMessage] = useState("");
    const [stat1LableMessageType, setStat1LableMessageType] = useState("");

    const [stat1Count, setStat1Count] = useState(stats != null ? stats.stat_1.count : "");
    const [hasStat1CountMessage, setHasStat1CountMessage] = useState(false);
    const [stat1CountMessage, setStat1CountMessage] = useState("");
    const [stat1CountMessageType, setStat1CountMessageType] = useState("");
    
    const [stat2Lable, setStat2Lable] = useState(stats != null ? stats.stat_2.lable : "");
    const [hasStat2LableMessage, setHasStat2LableMessage] = useState(false);
    const [stat2LableMessage, setStat2LableMessage] = useState("");
    const [stat2LableMessageType, setStat2LableMessageType] = useState("");

    const [stat2Count, setStat2Count] = useState(stats != null ? stats.stat_2.count : "");
    const [hasStat2CountMessage, setHasStat2CountMessage] = useState(false);
    const [stat2CountMessage, setStat2CountMessage] = useState("");
    const [stat2CountMessageType, setStat2CountMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateStatsSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(stat1Lable.trim() === ""){
            setHasStat1LableMessage(true);
            setStat1LableMessage("Invalid Stat 1 Lable");
            setStat1LableMessageType("error");
        }
        else{
            setHasStat1LableMessage(false);
            setStat1LableMessage("");
            setStat1LableMessageType("");
        }

        if(stat1Count.trim() === ""){
            setHasStat1CountMessage(true);
            setStat1CountMessage("Invalid Stat 1 Count");
            setStat1CountMessageType("error");
        }
        else{
            setHasStat1CountMessage(false);
            setStat1CountMessage("");
            setStat1CountMessageType("");
        }

        if(stat2Lable.trim() === ""){
            setHasStat2LableMessage(true);
            setStat2LableMessage("Invalid Stat 2 Lable");
            setStat2LableMessageType("error");
        }
        else{
            setHasStat2LableMessage(false);
            setStat2LableMessage("");
            setStat2LableMessageType("");
        }

        if(stat2Count.trim() === ""){
            setHasStat2CountMessage(true);
            setStat2CountMessage("Invalid Stat 2 Count");
            setStat2CountMessageType("error");
        }
        else{
            setHasStat2CountMessage(false);
            setStat2CountMessage("");
            setStat2CountMessageType("");
        }

        if(stat1Lable.trim() === "" || stat1Count.trim() === "" || stat2Lable.trim() === "" || stat2Count.trim() === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveAboutStatsReq = await AboutPageServices.updateAboutStats({
                stat_1_lable: stat1Lable,
                stat_1_count: stat1Count,
                stat_2_lable: stat2Lable,
                stat_2_count: stat2Count,
                token: user.access_token
            });

            if(saveAboutStatsReq.status === false){
                ToastAlert.notifyError(saveAboutStatsReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveAboutStatsReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateStatsPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateStatsSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutStat1Lable.hasLable}
                        lable={inputs.updateHomePageAboutStat1Lable.lable}
                        isMandatory={inputs.updateHomePageAboutStat1Lable.is_mandatory}
                        hasMessage={hasStat1LableMessage}
                        message={stat1LableMessage}
                        messageType={stat1LableMessageType}
                        isLimited={inputs.updateHomePageAboutStat1Lable.is_limited}
                        limit={inputs.updateHomePageAboutStat1Lable.max_characters}
                        value={stat1Lable}
                    >
                        <input 
                            type={inputs.updateHomePageAboutStat1Lable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutStat1Lable.placeholder}
                            onChange={(e) => setStat1Lable(e.target.value)}
                            value={stat1Lable}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutStat1Count.hasLable}
                        lable={inputs.updateHomePageAboutStat1Count.lable}
                        isMandatory={inputs.updateHomePageAboutStat1Count.is_mandatory}
                        hasMessage={hasStat1CountMessage}
                        message={stat1CountMessage}
                        messageType={stat1CountMessageType}
                        isLimited={inputs.updateHomePageAboutStat1Count.is_limited}
                        limit={inputs.updateHomePageAboutStat1Count.max_characters}
                        value={stat1Count}
                    >
                        <input 
                            type={inputs.updateHomePageAboutStat1Count.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutStat1Count.placeholder}
                            onChange={(e) => setStat1Count(e.target.value)}
                            value={stat1Count}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutStat2Lable.hasLable}
                        lable={inputs.updateHomePageAboutStat2Lable.lable}
                        isMandatory={inputs.updateHomePageAboutStat2Lable.is_mandatory}
                        hasMessage={hasStat2LableMessage}
                        message={stat2LableMessage}
                        messageType={stat2LableMessageType}
                        isLimited={inputs.updateHomePageAboutStat2Lable.is_limited}
                        limit={inputs.updateHomePageAboutStat2Lable.max_characters}
                        value={stat2Lable}
                    >
                        <input 
                            type={inputs.updateHomePageAboutStat2Lable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutStat2Lable.placeholder}
                            onChange={(e) => setStat2Lable(e.target.value)}
                            value={stat2Lable}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.updateHomePageAboutStat2Count.hasLable}
                        lable={inputs.updateHomePageAboutStat2Count.lable}
                        isMandatory={inputs.updateHomePageAboutStat2Count.is_mandatory}
                        hasMessage={hasStat2CountMessage}
                        message={stat2CountMessage}
                        messageType={stat2CountMessageType}
                        isLimited={inputs.updateHomePageAboutStat2Count.is_limited}
                        limit={inputs.updateHomePageAboutStat2Count.max_characters}
                        value={stat2Count}
                    >
                        <input 
                            type={inputs.updateHomePageAboutStat2Count.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.updateHomePageAboutStat2Count.placeholder}
                            onChange={(e) => setStat2Count(e.target.value)}
                            value={stat2Count}
                        />
                    </TextInputRT>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateStatsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )

}

export default UpdateAboutUsPageAboutStatsPopUp