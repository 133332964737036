import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { icons } from '../../../../constants'
import AvatarServices from '../../../../services/Avatar';
import { ToastAlert, globals } from '../../../../utils';
import PlainContainer from '../../../core/containers/PlainContainer'
import SelectUserAvatar from './SelectUserAvatar'

const UserAddPreview = ({ user, name="User Full Name", role="Admin", email="admin-user@karnataka.gov.in", phone="1234567890", avatarChanged }) => {

    const [showSelectAvatar, setShowSelectAvatar] = useState(false);

    const parentVariant = {
        hidden:{ opacity: 0, height: 0 },
        visible: {
            opacity: 1, 
            height: 'auto'
        },
        exit: {
            opacity: 0, 
            height: 0,
            transition: { delay: 0.1, duration: 0.3 }
        }
    }

    const containerVariant = {
        hidden: {
            transition: {
                staggerChildren: 0.1,
                staggerDirection: -1
            }
        },
        visible: {
            transition: {
                delay: 0.5,
                staggerChildren: 0.2,
                staggerDirection: 1
            }
        }
    }

    const childVariant = {
        hidden: {
            opacity: 0,
            x: -10
        },
        visible: {
            opacity: 1,
            x: 0,
        }
    }

    const [avatars, setAvatars] = useState([]);

    const [defaultAvatar, setDefaultAvatar] = useState(null);

    const [selectedAvatar, setSelectedAvatar] = useState(null);

    const handleGetAvatarsRequest = async () => {
        const getAvatarsReq = await AvatarServices.getPublicAvatars();

        if(getAvatarsReq.status === false){
            ToastAlert.notifyError(getAvatarsReq.message);
            setAvatars([]);
        }
        else{
            if(getAvatarsReq.data.length > 0){
                setAvatars(getAvatarsReq.data);
                const defaultAvatarObj = getAvatarsReq.data.filter(avatar => avatar.is_default === true);
                setDefaultAvatar(defaultAvatarObj);
            }
            else{
                setAvatars([]);
            }
        }
    }

    useEffect(() => {
        handleGetAvatarsRequest();
    }, []);

    useEffect(() => {
        if(defaultAvatar !== null){
            if(defaultAvatar.length > 0){
                if(selectedAvatar === null){
                    setSelectedAvatar(defaultAvatar[0]);
                    avatarChanged(defaultAvatar[0]);
                }
            }
        }
    }, [defaultAvatar]);

    const handleAvatarChanged = async (e) => {
        console.log(e);
        setSelectedAvatar(e);
        avatarChanged(e);
    }

    useEffect(() => {
        console.log(selectedAvatar);
    }, [selectedAvatar]);

    return (
        <div className="addUserPreviewMain">
            <div className="addUserPreviewVisual">
                <div className="addUserPreviewVisualMain">
                    <div className="addUserPreviewVisualBubble">
                        <div className="addUserPreviewVisualBubbleImageContainer">
                            {
                                selectedAvatar === null
                                ?
                                <img src={user.avatar === "" ? '/images/default-avatar.jpg' : user?.avatar} className="addUserPreviewVisualBubbleImage" alt="" />
                                :
                                <img src={`${globals.API_BASE_URL}${selectedAvatar?.image?.image_url?.thumbnail?.high_res}`} className="addUserPreviewVisualBubbleImage" alt="" />
                            }
                            
                        </div>
                        <div className="addUserPreviewVisualBubbleEdit">
                            {
                                showSelectAvatar
                                ?
                                    <div className="addUserPreviewVisualBubbleEditSave" onClick={() => setShowSelectAvatar(false)}>
                                        <p className="addUserPreviewVisualBubbleEditSaveTxt">close</p>
                                    </div>
                                :
                                    <div className="addUserPreviewVisualBubbleEditDefault" onClick={() => setShowSelectAvatar(true)}>
                                        <p className="addUserPreviewVisualBubbleEditDefaultTxt">edit</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                <AnimatePresence>
                    {showSelectAvatar && (
                        <motion.div style={{float: "left", width: "100%", display: "flex", justifyContent: "center"}} 
                            variants={parentVariant}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            <SelectUserAvatar user={user} containerVariant={containerVariant} childVariant={childVariant} avatars={avatars} selectedAvatar={selectedAvatar} avatarChanged={(e) => handleAvatarChanged(e)} />
                        </motion.div>
                    )}
                </AnimatePresence>
            }
            
            <div className={`${showSelectAvatar ? "hasMargin" : ""} addUserPreviewContent`}>
                <div className="addUserPreviewContentNameRole">
                    <div className="addUserPreviewContentName">
                        <p className="addUserPreviewContentNameTxt">{name}</p>
                    </div>
                    <div className="addUserPreviewContentRole">
                        <p className="addUserPreviewContentRoleTxt">Role: {role}</p>
                    </div>
                </div>
                <div className="addUserPreviewContentEmailPhone">
                    <div className="addUserPreviewContentEmail">
                        <p className="addUserPreviewContentEmailTxt">{email}</p>
                    </div>
                    <div className="addUserPreviewContentPhone">
                        <p className="addUserPreviewContentPhoneTxt">+91 {phone}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAddPreview