import React, { useEffect } from "react";
import AppRouter from "./navigation/AppRouter";
import { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from "react-redux";
import globals from "./utils/Config";
import CheckInternetStatus from "./components/core/containers/CheckInternetStatus";
import { logout } from "./reducers/User";

const App = () => {
	const user = useSelector((state) => state.user.value);

	console.log(user);

	const dispatch = useDispatch();

	const logoutUser = () => {
		dispatch(logout());
	}

	// useEffect(() => {
	// 	logoutUser();
	// }, []);
	
	return (
		<>
			<AppRouter user={user} />
			<Toaster />
			{/* <CheckInternetStatus /> */}
		</>
	)
}

export default App;
