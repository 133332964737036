/* DEVELOPMENT VARIABLES */

// const CMS_AUTHOR="LookitLive I2R Tech Innovations LLP"
// const CMS_VERSION="v1"
// const API_BASE_URL="http://localhost:4040/"
// const BASE_URL="/"
// const JWT_SECRET="v1Secret!1001!UnwBkd"
// const FIRST_PAGE=1
// const DEFAULT_PAGE_SIZE=10
// const MAX_IDLE_TIME=10
// const IP_ADDRESS_API_URL="https://geolocation-db.com/json/"

/* PRODUCTION VARIABLES */

const CMS_AUTHOR="LookitLive I2R Tech Innovations LLP"
const CMS_VERSION="v1"
const API_BASE_URL="https://api.uniwear.co/"
const BASE_URL="/"
const JWT_SECRET="v1Secret!1001!UnwBkd"
const FIRST_PAGE=1
const DEFAULT_PAGE_SIZE=10
const MAX_IDLE_TIME=10
const IP_ADDRESS_API_URL="https://geolocation-db.com/json/"

const globals = {
    CMS_AUTHOR,
    CMS_VERSION,
    API_BASE_URL,
    BASE_URL,
    JWT_SECRET,
    FIRST_PAGE,
    DEFAULT_PAGE_SIZE,
    MAX_IDLE_TIME,
    IP_ADDRESS_API_URL
}

export default globals