import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT'
import FileUploadInput from '../../../core/form/FileUploadInput';
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { FooterServices } from '../../../../services';
import TextAreaInput from '../../../core/form/TextAreaInput';

const UpdateFooterSectionHeadingsPopUp = ({ user, section_headings, concludeUpdateSectionHeadingsPopUp }) => {

    const [description, setDescription] = useState(section_headings != null ? section_headings.description : "");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Logo");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateSectionHeadingSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(description.trim() === ""){
            setHasDescriptionMessage(true);
            setDescriptionMessage("Invalid Description");
            setDescriptionMessageType("error");
        }
        else{
            setHasDescriptionMessage(false);
            setDescriptionMessage("");
            setDescriptionMessageType("");
        }

        if(section_headings === null){
            if(selectedImages.length === 0){
                setImageIsError(true);
            }
            else{
                setImageIsError(false);
            }

            if(description.trim() === "" || selectedImages.length === 0){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const saveSectionHeadingReq = await FooterServices.updateSectionHeadings({
                    description: description,
                    logo: selectedImages[0],
                    token: user.access_token
                });
    
                if(saveSectionHeadingReq.status === false){
                    ToastAlert.notifyError(saveSectionHeadingReq.message);
                    setSubmitButtonLoading(false);
                }
                else{
                    ToastAlert.notifySuccess(saveSectionHeadingReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonLable('Success');
                    concludeUpdateSectionHeadingsPopUp({
                        status: true
                    })
                }
            }
        }
        else{
            if(description.trim() === ""){
                setSubmitButtonLoading(false);
                return
            }
            else{
                const saveSectionHeadingsReq = await FooterServices.updateSectionHeadings({
                    description: description,
                    logo: selectedImages.length > 0 ? selectedImages[0] : section_headings.logo.id,
                    token: user.access_token
                });
    
                if(saveSectionHeadingsReq.status === false){
                    ToastAlert.notifyError(saveSectionHeadingsReq.message);
                    setSubmitButtonLoading(false);
                }
                else{
                    ToastAlert.notifySuccess(saveSectionHeadingsReq.message);
                    setSubmitButtonLoading(false);
                    setSubmitButtonDisabled(true);
                    setSubmitButtonBg('success');
                    setSubmitButtonLable('Success');
                    concludeUpdateSectionHeadingsPopUp({
                        status: true
                    })
                }
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateSectionHeadingSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextAreaInput 
                        hasLable={inputs.footerSectionHeadingsDescription.hasLable}
                        lable={inputs.footerSectionHeadingsDescription.lable}
                        isMandatory={inputs.footerSectionHeadingsDescription.is_mandatory}
                        hasIcon={false}
                        hasMessage={hasDescriptionMessage}
                        message={descriptionMessage}
                        messageType={descriptionMessageType}
                    >
                        <textarea 
                            type="text" 
                            className="cmsFormStepInputTextArea" 
                            placeholder={"Enter Description"}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            defaultValue={description}
                        >
                        </textarea>
                    </TextAreaInput>

                    <FileUploadInput 
                        data={imageData}
                        user={user} 
                        lable={imagePopUpLable} 
                        acceptedFileType={imageType} 
                        isPreview={section_headings === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${section_headings?.logo?.image_url?.full?.high_res}`}
                        isMultiple={imageIsMultiple} 
                        maxSelection={imageMaxFileSelection} 
                        isError={imageIsError}
                        action={(e) => handleImageSelection(e)} 
                    />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateSectionHeadingsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateFooterSectionHeadingsPopUp