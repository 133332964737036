import React from 'react'
import { icons } from '../../../constants'
import { functions } from '../../../utils'

const DetailsItemWithPreview = ({ lable, preview, previewType, fileName, fileExtension, fileSize }) => {
    return (
        <div className="sectionFieldItem">
            
            <div className={`sectionFieldItemLableContent`}>
                <div className="sectionFieldItemLable">
                    <p className="sectionFieldItemLableTxt">{lable}</p>
                </div>
                <div className="sectionFieldItemPreviewSection">
                    <div className="sectionFieldItemPreviewSectionMain">
                        <div className="sectionFieldItemPreview">
                            {
                                previewType === "pdf"
                                ?
                                    <div className="sectionFieldItemPreviewIcon">
                                        <i className={`${icons.filePDF} sectionFieldItemPreviewIco` } />
                                    </div>
                                :
                                previewType === "icon"
                                ?
                                    <div className="sectionFieldItemPreviewIcon">
                                        <i className={`${preview} sectionFieldItemPreviewIco` } />
                                    </div>
                                :
                                previewType === "image"
                                ?
                                    <div className="sectionFieldItemPreviewInner">
                                        <div className="sectionFieldItemPreviewImage">
                                            <img src={preview} className="sectionFieldItemPreviewImg" alt="" />
                                        </div>
                                    </div>
                                :
                                    <></>
                            }
                            
                        </div>

                        <div className="sectionFieldItemPreviewContent">
                            <div className="sectionFieldItemPreviewContentHeading">
                                {
                                    previewType !== "icon"
                                    ?
                                    <p className="sectionFieldItemPreviewContentHeadingTxt">{fileName}.{fileExtension}</p>
                                    :
                                    <p className="sectionFieldItemPreviewContentHeadingTxt">{preview}</p>
                                }
                            </div>
                            {
                                previewType !== "icon"
                                ?
                                <div className="sectionFieldItemPreviewContentSize">
                                    <p className="sectionFieldItemPreviewContentSizeTxt">Size: {functions.getReadableFileSize(fileSize)}</p>
                                </div>
                                :
                                <></>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailsItemWithPreview