import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../../constants';
import TextInputRT from '../../../../core/form/TextInputRT';
import TextAreaInput from '../../../../core/form/TextAreaInput';
import FileUploadInput from '../../../../core/form/FileUploadInput';
import Form from '../../../../form/Form';
import Button from '../../../../core/dashboard/Button';
import FormActions from '../../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../../utils';
import SplitContainer from '../../../../core/containers/SplitContainer';
import PlainContainer from '../../../../core/containers/PlainContainer';
import { AboutPageServices } from '../../../../../services';

const UpdateAboutUspageAboutHighlightPopUp = ({ user, highlight, concludeUpdateHighlightPopUp }) => {

    const [lable, setLable] = useState(highlight != null ? highlight.lable : "");
    const [hasLableMessage, setHasLableMessage] = useState(false);
    const [lableMessage, setLableMessage] = useState("");
    const [lableMessageType, setLableMessageType] = useState("");

    const [description, setDescription] = useState(highlight != null ? highlight.description : "");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateHighlightSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(lable.trim() === ""){
            setHasLableMessage(true);
            setLableMessage("Invalid Lable");
            setLableMessageType("error");
        }
        else{
            setHasLableMessage(false);
            setLableMessage("");
            setLableMessageType("");
        }

        if(description.trim() === ""){
            setHasDescriptionMessage(true);
            setDescriptionMessage("Invalid Description");
            setDescriptionMessageType("error");
        }
        else{
            setHasDescriptionMessage(false);
            setDescriptionMessage("");
            setDescriptionMessageType("");
        }

        if(lable.trim() === "" || description.trim() === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveHighlightReq = await AboutPageServices.updateAboutHighlight({
                id: highlight.id,
                lable: lable,
                description: description,
                icon: selectedImages.length > 0 ? selectedImages[0] : highlight.icon.id,
                token: user.access_token
            });

            if(saveHighlightReq.status === false){
                ToastAlert.notifyError(saveHighlightReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveHighlightReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateHighlightPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateHighlightSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.whyChooseUsHighlightLable.hasLable}
                        lable={inputs.whyChooseUsHighlightLable.lable}
                        isMandatory={inputs.whyChooseUsHighlightLable.is_mandatory}
                        hasMessage={hasLableMessage}
                        message={lableMessage}
                        messageType={lableMessageType}
                        isLimited={inputs.whyChooseUsHighlightLable.is_limited}
                        limit={inputs.whyChooseUsHighlightLable.max_characters}
                        value={lable}
                    >
                        <input 
                            type={inputs.whyChooseUsHighlightLable.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.whyChooseUsHighlightLable.placeholder}
                            onChange={(e) => setLable(e.target.value)}
                            value={lable}
                        />
                    </TextInputRT>

                    <TextAreaInput 
                        hasLable={inputs.whyChooseUsHighlightDescription.hasLable}
                        lable={inputs.whyChooseUsHighlightDescription.lable}
                        isMandatory={inputs.whyChooseUsHighlightDescription.is_mandatory}
                        hasIcon={false}
                        hasMessage={hasDescriptionMessage}
                        message={descriptionMessage}
                        messageType={descriptionMessageType}
                    >
                        <textarea 
                            type="text" 
                            className="cmsFormStepInputTextArea" 
                            placeholder={"Enter Description"}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            defaultValue={description}
                        >
                        </textarea>
                    </TextAreaInput>

                    <FileUploadInput 
                        data={imageData}
                        user={user} 
                        lable={imagePopUpLable} 
                        acceptedFileType={imageType} 
                        isPreview={highlight === null ? false : true}
                        previewType={"image"}
                        preview={`${globals.API_BASE_URL}${highlight?.icon?.image_url?.full?.high_res}`}
                        isMultiple={imageIsMultiple} 
                        maxSelection={imageMaxFileSelection} 
                        isError={imageIsError}
                        action={(e) => handleImageSelection(e)} 
                    />

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateHighlightPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateAboutUspageAboutHighlightPopUp