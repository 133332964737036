import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import TextInputRT from '../../../../components/core/form/TextInputRT';
import TextAreaInput from '../../../../components/core/form/TextAreaInput';
import Button from '../../../../components/core/dashboard/Button';
import { icons } from '../../../../constants';
import PlainContainer from '../../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../../components/core/containers/SplitContainer';
import Card from '../../../../components/core/dashboard/Card';
import { defaults, ToastAlert, functions, globals } from '../../../../utils';
import { HomePageServices } from '../../../../services';
import CardHeading from '../../../../components/core/typography/dashboard/CardHeading';
import CardDescription from '../../../../components/core/typography/dashboard/CardDescription';
import FileUploadInput from '../../../../components/core/form/FileUploadInput';
import FormActions from '../../../../components/form/FormActions';

const UpdateHomePageSEOPage = ({ user }) => {
    const navigate = useNavigate();

    const [seo, setSEO] = useState(null);

    const handleGetSEORequest = async () => {
        const getSEOReq = await HomePageServices.getAuthSEO({
            token: user.access_token
        });

        if(getSEOReq.status === false){
            ToastAlert.notifyError(getSEOReq.message);
            setSEO(null);
        }
        else{
            console.log(getSEOReq.data);
            setSEO(getSEOReq.data);
            setTitle(getSEOReq?.data?.title);
            setDescription(getSEOReq?.data?.description);
            setKeywords(getSEOReq?.data?.keywords);
            setCanonicalURL(getSEOReq?.data?.canonical_url);
            setTwitterHandler(getSEOReq?.data?.twitter_handler);
        }
    }

    useEffect(() => {
        handleGetSEORequest();
    }, []);

    const [title, setTitle] = useState("");
    const [hasTitleIcon, setHasTitleIcon] = useState(false);
    const [titleIcon, setTitleIcon] = useState("");
    const [titleIconPosition, setTitleIconPosition] = useState("right");
    const [titleIconType, setTitleIconType] = useState("");
    const [hasTitleMessage, setHasTitleMessage] = useState(false);
    const [titleMessage, setTitleMessage] = useState("");
    const [titleMessageType, setTitleMessageType] = useState("");

    const [description, setDescription] = useState("");
    const [hasDescriptionIcon, setHasDescriptionIcon] = useState(false);
    const [descriptionIcon, setDescriptionIcon] = useState("");
    const [descriptionIconPosition, setDescriptionIconPosition] = useState("right");
    const [descriptionIconType, setDescriptionIconType] = useState("");
    const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
    const [descriptionMessage, setDescriptionMessage] = useState("");
    const [descriptionMessageType, setDescriptionMessageType] = useState("");

    const [keywords, setKeywords] = useState("");
    const [hasKeywordsIcon, setHasKeywordsIcon] = useState(false);
    const [keywordsIcon, setKeywordsIcon] = useState("");
    const [keywordsIconPosition, setKeywordsIconPosition] = useState("right");
    const [keywordsIconType, setKeywordsIconType] = useState("");
    const [hasKeywordsMessage, setHasKeywordsMessage] = useState(false);
    const [keywordsMessage, setKeywordsMessage] = useState("");
    const [keywordsMessageType, setKeywordsMessageType] = useState("");

    const [canonicalURL, setCanonicalURL] = useState("");
    const [hasCanonicalURLIcon, setHasCanonicalURLIcon] = useState(false);
    const [canonicalURLIcon, setCanonicalURLIcon] = useState("");
    const [canonicalURLIconPosition, setCanonicalURLIconPosition] = useState("right");
    const [canonicalURLIconType, setCanonicalURLIconType] = useState("");
    const [hasCanonicalURLMessage, setHasCanonicalURLMessage] = useState(false);
    const [canonicalURLMessage, setCanonicalURLMessage] = useState("");
    const [canonicalURLMessageType, setCanonicalURLMessageType] = useState("");

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageType, setImageType] = useState("image");
    const [imageIsMultiple, setImageIsMultiple] = useState(false);
    const [imageMaxFileSelection, setImageMaxFileSelection] = useState(1);
    const [imagePopUpLable, setImagePopUpLable] = useState("Image");
    const [imageIsError, setImageIsError] = useState(false);
    const [imageData, setImageData] = useState(null);

    const [twitterHandler, setTwitterHandler] = useState("");
    const [hasTwitterHandlerIcon, setHasTwitterHandlerIcon] = useState(false);
    const [twitterHandlerIcon, setTwitterHandlerIcon] = useState("");
    const [twitterHandlerIconPosition, setTwitterHandlerIconPosition] = useState("right");
    const [twitterHandlerIconType, setTwitterHandlerIconType] = useState("");
    const [hasTwitterHandlerMessage, setHasTwitterHandlerMessage] = useState(false);
    const [twitterHandlerMessage, setTwitterHandlerMessage] = useState("");
    const [twitterHandlerMessageType, setTwitterHandlerMessageType] = useState("");

    const [submitButtonHasIcon, setSubmitButtonHasIcon] = useState(false);
    const [submitButtonIconPosition, setSubmitButtonIconPosition] = useState('left');
    const [submitButtonIco, setSubmitButtonIco] = useState(icons.tick);
    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");
    
    const handleImageSelection = async (e) => {
        if(e.status === true){
            setSelectedImages([...e.files]);
            setImageIsError(false);
        }
        else{
            setSelectedImages([]);
        }
    }

    const handleUpdateSEOSubmission = async (e) => {
        e.preventDefault();

        const saveSEOReq = await HomePageServices.updateSEO({
            title: title,
            description: description,
            keywords: keywords,
            canonical_url: canonicalURL,
            twitter_handler: twitterHandler,
            image: selectedImages.length > 0 ? selectedImages[0] : seo?.image?.id,
            token: user.access_token
        });

        if(saveSEOReq.status === false){
            ToastAlert.notifyError(saveSEOReq.message);
            setSubmitButtonLoading(false);
        }
        else{
            ToastAlert.notifySuccess(saveSEOReq.message);
            setSubmitButtonLoading(false);
            setSubmitButtonDisabled(true);
            setSubmitButtonBg('success');
            setSubmitButtonHasIcon(true);
            setSubmitButtonLable('Success');
            navigate(-1);
        }
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Update SEO'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to SEO for the home page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "15px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <CardHeading heading={"Update metadata to boost SEO"} color="dark" />
                        <CardDescription description={"you can still modify this content later"} />

                        <PlainContainer type='full' styles={{marginTop: "30px"}}>
                            <form onSubmit={(e) => handleUpdateSEOSubmission(e)} noValidate>
                                <div className="cmsForm">
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="Title"
                                        isMandatory={false}
                                        hasIcon={hasTitleIcon}
                                        icon={titleIcon}
                                        iconPosition={titleIconPosition}
                                        iconType={titleIconType}
                                        hasMessage={hasTitleMessage}
                                        message={titleMessage}
                                        messageType={titleIconType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Page Title"}
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                    </TextInputRT>
                                    <TextAreaInput 
                                        hasLable={true}
                                        lable={"description"}
                                        isMandatory={false}
                                        hasIcon={false}
                                        hasMessage={hasDescriptionMessage}
                                        message={descriptionMessage}
                                        messageType={descriptionMessageType}
                                    >
                                        <textarea 
                                            className="cmsFormStepInputTextArea" 
                                            placeholder={"Enter Page Description"}
                                            onChange={(e) => setDescription(e.target.value)}
                                            rows={3}
                                            value={description}
                                        >
                                        </textarea>
                                    </TextAreaInput>
                                    <TextAreaInput 
                                        hasLable={true}
                                        lable={"keywords"}
                                        isMandatory={false}
                                        hasIcon={false}
                                        hasMessage={hasKeywordsMessage}
                                        message={keywordsMessage}
                                        messageType={keywordsMessageType}
                                    >
                                        <textarea 
                                            type="text" 
                                            className="cmsFormStepInputTextArea" 
                                            placeholder={"Enter Page Keywords"}
                                            onChange={(e) => setKeywords(e.target.value)}
                                            rows={3}
                                            value={keywords}
                                        >
                                        </textarea>
                                    </TextAreaInput>
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="Canonical URL"
                                        isMandatory={false}
                                        hasIcon={hasCanonicalURLIcon}
                                        icon={canonicalURLIcon}
                                        iconPosition={canonicalURLIconPosition}
                                        iconType={canonicalURLIconType}
                                        hasMessage={hasCanonicalURLMessage}
                                        message={canonicalURLMessage}
                                        messageType={canonicalURLMessageType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Canonical URL"}
                                            onChange={(e) => setCanonicalURL(e.target.value)}
                                            value={canonicalURL}
                                        />
                                    </TextInputRT>
                                    <TextInputRT 
                                        hasLable={true}
                                        lable="Twitter Handler"
                                        isMandatory={false}
                                        hasIcon={hasTwitterHandlerIcon}
                                        icon={twitterHandlerIcon}
                                        iconPosition={twitterHandlerIconPosition}
                                        iconType={twitterHandlerIconType}
                                        hasMessage={hasTwitterHandlerMessage}
                                        message={twitterHandlerMessage}
                                        messageType={twitterHandlerMessageType}
                                    >
                                        <input 
                                            type="text" 
                                            className="cmsFormStepInputText" 
                                            placeholder={"Enter Twitter Handler"}
                                            onChange={(e) => setTwitterHandler(e.target.value)}
                                            value={twitterHandler}
                                        />
                                    </TextInputRT>
                                    <FileUploadInput 
                                        data={imageData}
                                        user={user} 
                                        lable={imagePopUpLable} 
                                        acceptedFileType={imageType} 
                                        isPreview={seo?.image === undefined ? false : true}
                                        previewType={"image"}
                                        preview={`${globals.API_BASE_URL}${seo?.image?.image_url?.full?.high_res}`}
                                        isMultiple={imageIsMultiple} 
                                        maxSelection={imageMaxFileSelection} 
                                        isError={imageIsError}
                                        action={(e) => handleImageSelection(e)} 
                                    />
                                    <FormActions>
                                        <Button 
                                            type="submit"
                                            bgType="fill"
                                            width="auto"
                                            bg={submitButtonBg}
                                            borderRadius="short"
                                            hasIcon={submitButtonHasIcon}
                                            iconPosition={submitButtonIconPosition}
                                            icon={submitButtonIco}
                                            disabled={submitButtonDisabled}
                                            isLoading={submitButtonLoading}
                                        >
                                            {submitButtonLable}
                                        </Button>
                                        <Button 
                                            type="button"
                                            bgType="fill"
                                            width="auto"
                                            bg={cancelButtonBg}
                                            borderRadius="short"
                                            action={() => navigate(-1)}
                                        >
                                            {cancelButtonLable}
                                        </Button>
                                    </FormActions>
                                </div>
                            </form>
                        </PlainContainer>
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default UpdateHomePageSEOPage

