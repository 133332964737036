import React, { useState, useEffect } from 'react'
import { icons, inputs } from '../../../../constants';
import TextInputRT from '../../../core/form/TextInputRT'
import Form from '../../../form/Form';
import Button from '../../../core/dashboard/Button';
import FormActions from '../../../form/FormActions';
import { ToastAlert, globals, statusCodes, validations } from '../../../../utils';
import SplitContainer from '../../../core/containers/SplitContainer';
import PlainContainer from '../../../core/containers/PlainContainer';
import { FooterServices } from '../../../../services';
import TextAreaInput from '../../../core/form/TextAreaInput';

const UpdateFooterCopyrightDetailsPopUp = ({ user, copyright_details, concludeUpdateCopyrightDetailsPopUp }) => {

    const [year, setYear] = useState(copyright_details != null ? copyright_details.copyright.year : "");
    const [hasYearMessage, setHasYearMessage] = useState(false);
    const [yearMessage, setYearMessage] = useState("");
    const [yearMessageType, setYearMessageType] = useState("");

    const [companyName, setCompanyName] = useState(copyright_details != null ? copyright_details.copyright.company_name : "");
    const [hasCompanyNameMessage, setHasCompanyNameMessage] = useState(false);
    const [companyNameMessage, setCompanyNameMessage] = useState("");
    const [companyNameMessageType, setCompanyNameMessageType] = useState("");

    const [partnerName, setPartnerName] = useState(copyright_details != null ? copyright_details.powered_by.partner_name : "");
    const [hasPartnerNameMessage, setHasPartnerNameMessage] = useState(false);
    const [partnerNameMessage, setPartnerNameMessage] = useState("");
    const [partnerNameMessageType, setPartnerNameMessageType] = useState("");

    const [action, setAction] = useState(copyright_details != null ? copyright_details.powered_by.action : "");
    const [hasActionMessage, setHasActionMessage] = useState(false);
    const [actionMessage, setActionMessage] = useState("");
    const [actionMessageType, setActionMessageType] = useState("");

    const [submitButtonLable, setSubmitButtonLable] = useState("Submit");
    const [submitButtonBg, setSubmitButtonBg] = useState("dashboard");

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    const [cancelButtonLable, setCancelButtonLable] = useState("Cancel");
    const [cancelButtonBg, setCancelButtonBg] = useState("error");

    const handleUpdateCopyrightDetailsSubmission = async (e) => {
        e.preventDefault();

        setSubmitButtonLoading(true);

        if(year.trim() === ""){
            setHasYearMessage(true);
            setYearMessage("Invalid Year");
            setYearMessageType("error");
        }
        else{
            setHasYearMessage(false);
            setYearMessage("");
            setYearMessageType("");
        }

        if(companyName.trim() === ""){
            setHasCompanyNameMessage(true);
            setCompanyNameMessage("Invalid Company Name");
            setCompanyNameMessageType("error");
        }
        else{
            setHasCompanyNameMessage(false);
            setCompanyNameMessage("");
            setCompanyNameMessageType("");
        }

        if(partnerName.trim() === ""){
            setHasPartnerNameMessage(true);
            setPartnerNameMessage("Invalid Partner Name");
            setPartnerNameMessageType("error");
        }
        else{
            setHasPartnerNameMessage(false);
            setPartnerNameMessage("");
            setPartnerNameMessageType("");
        }

        if(action.trim() === ""){
            setHasActionMessage(true);
            setActionMessage("Invalid Action");
            setActionMessageType("error");
        }
        else{
            setHasActionMessage(false);
            setActionMessage("");
            setActionMessageType("");
        }

        if(year.trim() === "" || companyName.trim() === "" || partnerName.trim() === "" || action.trim() === ""){
            setSubmitButtonLoading(false);
            return
        }
        else{
            const saveCopyrightDetailsReq = await FooterServices.updateCopyrightPoweredBy({
                year: year,
                company_name: companyName,
                partner_name: partnerName,
                action: action,
                token: user.access_token
            });

            if(saveCopyrightDetailsReq.status === false){
                ToastAlert.notifyError(saveCopyrightDetailsReq.message);
                setSubmitButtonLoading(false);
            }
            else{
                ToastAlert.notifySuccess(saveCopyrightDetailsReq.message);
                setSubmitButtonLoading(false);
                setSubmitButtonDisabled(true);
                setSubmitButtonBg('success');
                setSubmitButtonLable('Success');
                concludeUpdateCopyrightDetailsPopUp({
                    status: true
                })
            }
        }
    }

    return (
        <div className="cmsDashboardPopUpContentCreateFolder">
            <form onSubmit={(e) => handleUpdateCopyrightDetailsSubmission(e)} noValidate>
                <div className="cmsForm">
                    <TextInputRT 
                        hasLable={inputs.footerCopyrightYear.hasLable}
                        lable={inputs.footerCopyrightYear.lable}
                        isMandatory={inputs.footerCopyrightYear.is_mandatory}
                        hasMessage={hasYearMessage}
                        message={yearMessage}
                        messageType={yearMessageType}
                        isLimited={inputs.footerCopyrightYear.is_limited}
                        limit={inputs.footerCopyrightYear.max_characters}
                        value={year}
                    >
                        <input 
                            type={inputs.footerCopyrightYear.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerCopyrightYear.placeholder}
                            onChange={(e) => setYear(e.target.value)}
                            value={year}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.footerCopyrightCompanyName.hasLable}
                        lable={inputs.footerCopyrightCompanyName.lable}
                        isMandatory={inputs.footerCopyrightCompanyName.is_mandatory}
                        hasMessage={hasCompanyNameMessage}
                        message={companyNameMessage}
                        messageType={companyNameMessageType}
                        isLimited={inputs.footerCopyrightCompanyName.is_limited}
                        limit={inputs.footerCopyrightCompanyName.max_characters}
                        value={companyName}
                    >
                        <input 
                            type={inputs.footerCopyrightCompanyName.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerCopyrightCompanyName.placeholder}
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.footerCopyrightPartnerName.hasLable}
                        lable={inputs.footerCopyrightPartnerName.lable}
                        isMandatory={inputs.footerCopyrightPartnerName.is_mandatory}
                        hasMessage={hasPartnerNameMessage}
                        message={partnerNameMessage}
                        messageType={partnerNameMessageType}
                        isLimited={inputs.footerCopyrightPartnerName.is_limited}
                        limit={inputs.footerCopyrightPartnerName.max_characters}
                        value={partnerName}
                    >
                        <input 
                            type={inputs.footerCopyrightPartnerName.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerCopyrightPartnerName.placeholder}
                            onChange={(e) => setPartnerName(e.target.value)}
                            value={partnerName}
                        />
                    </TextInputRT>

                    <TextInputRT 
                        hasLable={inputs.footerCopyrightAction.hasLable}
                        lable={inputs.footerCopyrightAction.lable}
                        isMandatory={inputs.footerCopyrightAction.is_mandatory}
                        hasMessage={hasActionMessage}
                        message={actionMessage}
                        messageType={actionMessageType}
                        isLimited={inputs.footerCopyrightAction.is_limited}
                        limit={inputs.footerCopyrightAction.max_characters}
                        value={action}
                    >
                        <input 
                            type={inputs.footerCopyrightAction.type} 
                            className="cmsFormStepInputText" 
                            placeholder={inputs.footerCopyrightAction.placeholder}
                            onChange={(e) => setAction(e.target.value)}
                            value={action}
                        />
                    </TextInputRT>

                    <FormActions>
                        <Button 
                            type="submit"
                            bgType="fill"
                            width="auto"
                            bg={submitButtonBg}
                            borderRadius="short"
                            disabled={submitButtonDisabled}
                            isLoading={submitButtonLoading}
                        >
                            {submitButtonLable}
                        </Button>
                        <Button 
                            type="button"
                            bgType="fill"
                            width="auto"
                            bg={cancelButtonBg}
                            borderRadius="short"
                            action={() => concludeUpdateCopyrightDetailsPopUp({
                                status: false
                            })}
                        >
                            {cancelButtonLable}
                        </Button>
                    </FormActions>
                </div>
            </form>
        </div>
    )
}

export default UpdateFooterCopyrightDetailsPopUp