import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import DashboardHeaderSearchBar from '../../../components/core/form/DashboardHeaderSearchBar';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import Card from '../../../components/core/dashboard/Card';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableCheckbox from '../../../components/core/table/inputs/Checkbox';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import DistrictsListFilters from './DistrictsListFilters';
import DistrictServices from '../../../services/Location/District';
import { ToastAlert } from '../../../utils';
import Pagination from '../../../components/core/table/pagination';

const DistrictsListPage = ({ user }) => {
    const navigate = useNavigate();

    const [districts, setDistricts] = useState([]);
    const [totalDistrictsCount, setTotalDistrictsCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const handleGetDistrictsRequest = async () => {
        const getDistrictsReq = await DistrictServices.getAllDistricts({
            token: user.access_token,
            current_page: currentPage
        });

        if(getDistrictsReq.status === false){
            ToastAlert.notifyError(getDistrictsReq.message);
            setDistricts([]);
            setTotalDistrictsCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            setDistricts(getDistrictsReq.data.items);
            setTotalDistrictsCount(getDistrictsReq.data.total_items);
            setTotalPagesCount(getDistrictsReq.data.total_pages);
            setCurrentPage(getDistrictsReq.data?.current_page === undefined ? 1 : getDistrictsReq.data.current_page);
        }
    }

    const handleDeleteDistrictRequest = async (id) => {

        const deleteDistrictReq = await DistrictServices.deleteDistrict({
            token: user.access_token,
            id: id
        });

        if(deleteDistrictReq.status === false){
            ToastAlert.notifyError(deleteDistrictReq.message);
            setDistricts([]);
            setTotalDistrictsCount(0);
            setTotalPagesCount(0);
            setCurrentPage(1);
        }
        else{
            ToastAlert.notifySuccess(deleteDistrictReq.message);
            handleGetDistrictsRequest();
        }
    }

    useEffect(() => {
        handleGetDistrictsRequest();
    }, [currentPage]);

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Districts'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='List of All Districts in India.'
                />
                <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions>
            </DefaultHeader>

            <PlainContainer type="full" styles={{padding: '30px'}}>
                <Card hasHeading={false} isBottomAction={false}>
                    <PlainContainer type="full" styles={{marginBottom: '25px'}}>
                        <PlainContainer type="full" styles={{paddingTop: "15px", paddingRight: "25px", paddingBottom: "25px", paddingLeft: "25px"}}>
                            <DistrictsListFilters />
                        </PlainContainer>
                        <PlainContainer type='full'>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={0} hasChildren={true}>
                                        <TableCheckbox name={"items-selected-ckb"} />
                                    </TableHeadingItem>
                                    <TableHeadingItem size={2} lable={"name"} />
                                    <TableHeadingItem size={1} lable={"state"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={1} lable={"no. of cities"} />
                                    <TableHeadingItem size={0} lable={""} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>
                                <TableRows>
                                    {
                                        districts.map((district, i) => {
                                            return (
                                                <TableRow key={i} isCenter={false}>
                                                    <TableRowItem size={0} hasChildren={true} isCenter={false}>
                                                        <TableCheckbox name={`items-selected-ckb-${1}`} />
                                                    </TableRowItem>
                                                    <TableRowItem size={2} isCenter={false} hasPreview={false} lable={district?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={district?.state?.name?.en} />
                                                    <TableRowItem size={1} isCenter={false} lable={`${district?.created_by.first_name} (${district?.created_by?.role === "SUPER_ADMIN" ? "Super Admin" : "Admin" })`} />
                                                    <TableRowItem size={1} isCenter={false} lable={district?.cities} />
                                                    <TableRowItem size={0} isCenter={true} hasChildren={true}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={icons.eye} color={"dark"} action={() => console.log()} />
                                                        </TableRowIcons>
                                                    </TableRowItem>
                                                    <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                        <TableRowActions>
                                                            <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => navigate(`edit/${district.id}`)}>edit</TableButton>
                                                            <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteDistrictRequest(district.id)}>delete</TableButton>
                                                        </TableRowActions>
                                                    </TableRowItem>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableRows>
                            </Table>
                        </PlainContainer>
                        {
                            totalPagesCount > 1
                            ?
                                <PlainContainer type='full' styles={{ padding: "0px 25px" }}>
                                    <Pagination totalPages={totalPagesCount} setCurrentPage={setCurrentPage}/>
                                </PlainContainer>
                            :
                                <></>
                        }
                    </PlainContainer>
                </Card>
            </PlainContainer>
        </>
    )
}

export default DistrictsListPage