import React, { useEffect, useState, forwardRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DefaultHeader from '../../../components/dashboard/common/PageHeaders/DefaultHeader';
import DefaultContent from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/DefaultContent';
import HeaderActions from '../../../components/dashboard/common/PageHeaders/HeaderWidgets/HeaderActions';
import Button from '../../../components/core/dashboard/Button';
import { icons } from '../../../constants';
import PlainContainer from '../../../components/core/containers/PlainContainer';
import SplitContainer from '../../../components/core/containers/SplitContainer';
import Card from '../../../components/core/dashboard/Card';
import DetailsRow from '../../../components/core/containers/DetailsRow';
import DetailsItem from '../../../components/core/containers/DetailsItem';
import Table from '../../../components/core/table/containers/Table';
import TableHeading from '../../../components/core/table/containers/TableHeading';
import TableHeadingItem from '../../../components/core/table/containers/TableHeadingItem';
import TableRows from '../../../components/core/table/containers/TableRows';
import TableRow from '../../../components/core/table/containers/TableRow';
import TableRowItem from '../../../components/core/table/containers/TableRowItem';
import TableRowIcons from '../../../components/core/table/containers/TableRowIcons';
import TableRowIcon from '../../../components/core/table/containers/TableRowIcon';
import TableRowActions from '../../../components/core/table/containers/TableRowActions';
import TableButton from '../../../components/core/table/inputs/Button';
import { defaults, ToastAlert, functions, moments, globals } from '../../../utils';
import { HeaderServices } from '../../../services';
import AddSubHeaderActionPopUp from '../../../components/dashboard/popups/Header/AddSubHeaderActionPopUp';
import PopUp from '../../../components/core/popup';
import AddParentNavigationItemPopUp from '../../../components/dashboard/popups/Header/AddParentNavigationItemPopUp';
import NoDataContainer from '../../../components/core/containers/NoDataContainer';
import UpdateHeaderActionPopUp from '../../../components/dashboard/popups/Header/UpdateHeaderActionPopUp';
import DetailsItemWithPreview from '../../../components/core/containers/DetailsItemWithPreview';

const DragHandle = sortableHandle(() => <button className="draggableHandlerTableRow"><i className={`${icons.menu} draggableHandlerTableRowIco`}></i></button>);

const SortableParentNavigationItem = sortableElement(({value, index}) => (
    <TableRow key={index}>
        <DragHandle />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.lable} />
        <TableRowItem size={1} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? "page" : "URL"} />
        <TableRowItem size={2} isCenter={false} hasPreview={false} isCapital={false} lable={value?.action?.goto_type === "PAGE" ? value?.action?.goto_page?.path : value?.action?.goto_url } />
        <TableRowItem size={1} isCenter={false} hasPreview={false} isCapital={false} hasChildren={true}>
            <div className="clickableCountTable">
                <p className="clickableCountTableTxt">{value?.sub_items.length}</p>
            </div>
        </TableRowItem>
        <TableRowItem size={1} isCenter={false} hasPreview={false} lable={value?.created_by?.first_name} />
        <TableRowItem size={2} isCenter={false} hasChildren={true}>
            <TableRowActions>
                <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => console.log(value)}>edit</TableButton>
                <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => console.log(value.id)}>delete</TableButton>
            </TableRowActions>
        </TableRowItem>
    </TableRow>
));
  
const SortableParentNavigationContainer = sortableContainer(({children}) => {
    return <TableRows>{children}</TableRows>;
});

const HeaderPage = ({ user }) => {
    const navigate = useNavigate();

    const [subHeaderActions, setSubHeaderActions] = useState([]);

    const handleGetSubHeaderActionsRequest = async () => {
        const getSubHeaderActionsReq = await HeaderServices.getAuthSubHeaderActions({
            token: user.access_token
        });

        if(getSubHeaderActionsReq.status === false){
            ToastAlert.notifyError(getSubHeaderActionsReq.message);
            setSubHeaderActions([]);
        }
        else{
            setSubHeaderActions(getSubHeaderActionsReq.data);
        }
    }

    useEffect(() => {
        handleGetSubHeaderActionsRequest();
    }, []);

    const [showAddSubHeaderActionPopUp, setShowAddSubHeaderActionPopUp] = useState(false);

    const handleConcludeAddLeftActionPopUp = async (e) => {
        if(e.status === false){
            setShowAddSubHeaderActionPopUp(false);
        }
        else{
            setShowAddSubHeaderActionPopUp(false);
            handleGetSubHeaderActionsRequest();
        }
    }

    const handleEditSubHeaderAction = async (action_id) => {
        console.log(action_id);
    }

    const handleDeleteSubHeaderAction = async (action_id) => {
        console.log(action_id);
    }

    const [parentNavigationItems, setParentNavigationItems] = useState([]);

    const handleGetParentNavigationItemsRequest = async () => {
        const getParentNavigationItemsReq = await HeaderServices.getAuthParentNavigationItems({
            token: user.access_token
        });

        if(getParentNavigationItemsReq.status === false){
            ToastAlert.notifyError(getParentNavigationItemsReq.message);
            setParentNavigationItems([]);
        }
        else{
            console.log(getParentNavigationItemsReq.data);
            setParentNavigationItems(getParentNavigationItemsReq.data);
        }
    }

    useEffect(() => {
        handleGetParentNavigationItemsRequest();
    }, []);

    const [showAddParentNavigationItemPopUp, setShowAddParentNavigationItemPopUp] = useState(false);

    const handleConcludeAddParentNavigationItemPopUp = async (e) => {
        if(e.status === false){
            setShowAddParentNavigationItemPopUp(false);
        }
        else{
            setShowAddParentNavigationItemPopUp(false);
            handleGetParentNavigationItemsRequest();
        }
    }

    const [headerAction, setHeaderAction] = useState(null);

    const [showUpdateHeaderActionPopUp, setShowUpdateHeaderActionPopUp] = useState(false);

    const handleGetHeaderActionRequest = async () => {
        const getHeaderActionReq = await HeaderServices.getAuthHeaderAction({
            token: user.access_token
        });

        if(getHeaderActionReq.status === false){
            ToastAlert.notifyError(getHeaderActionReq.message);
            setHeaderAction(null);
        }
        else{
            console.log(getHeaderActionReq.data);
            setHeaderAction(getHeaderActionReq.data);
        }
    }

    useEffect(() => {
        handleGetHeaderActionRequest();
    }, []);

    const handleConcludeUpdateHeaderActionPopUp = async (e) => {
        if(e.status === false){
            setShowUpdateHeaderActionPopUp(false);
        }
        else{
            setShowUpdateHeaderActionPopUp(false);
            handleGetHeaderActionRequest();
        }
    }
    
    const handleSaveSortedParentNavigationList = async (list) => {

        const sortParentNavigationReq = await HeaderServices.sortParentNavigationItems({
            items: JSON.stringify(list),
            token: user.access_token
        });

        if(sortParentNavigationReq.status === false){
            ToastAlert.notifyError(sortParentNavigationReq.message);
        }
        else{
            ToastAlert.notifySuccess(sortParentNavigationReq.message);
            handleGetParentNavigationItemsRequest();
        }
    }

    const handleSortParentNavigationEnd = async ({oldIndex, newIndex}) => {
        const arrayMove = functions.getSortedArrayMove(parentNavigationItems, oldIndex, newIndex);

        const entityIdWithIndex = [];

        await Promise.all(arrayMove.map((item, i) => {
            let data = {
                id: item.id,
                index: i
            }
            entityIdWithIndex.push(data);
        }));

        handleSaveSortedParentNavigationList(entityIdWithIndex);

        setParentNavigationItems([...arrayMove]);
    }

    return (
        <>
            <DefaultHeader>
                <DefaultContent 
                    heading='Header'
                    descriptionIcon={''}
                    hasDescriptionIcon={false}
                    description='Manage everything related to header in this page'
                />
                {/* <HeaderActions>
                    <Button 
                        type="button"
                        bgType="fill"
                        width="auto"
                        bg="dashboard"
                        borderRadius="full"
                        hasIcon={true}
                        iconPosition="left"
                        icon={icons.add}
                        action={()=> navigate('add') }
                    >
                        Add New
                    </Button>
                </HeaderActions> */}
            </DefaultHeader>

            <PlainContainer styles={{marginTop: "25px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                <Card hasHeading={true} heading={"Sub header Actions"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddSubHeaderActionPopUp(true)} isBottomAction={false}>
                    <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                        <Table>
                            <TableHeading>
                                <TableHeadingItem size={3} lable={"lable"} />
                                <TableHeadingItem size={1} lable={"icon"} />
                                <TableHeadingItem size={2} lable={"created by"} />
                                <TableHeadingItem size={2} lable={"Actions"} />
                            </TableHeading>
                            <TableRows>
                                {
                                    subHeaderActions.map((action, i) => {
                                        return (
                                            <TableRow isCenter={false} key={i}>
                                                <TableRowItem size={3} isCenter={false} hasPreview={false} isCapital={false} lable={action?.lable} />
                                                <TableRowItem size={1} isCenter={false} hasPreview={false} hasChildren={true}>
                                                    <PlainContainer styles={{paddingLeft: "15px"}}>
                                                        <TableRowIcons>
                                                            <TableRowIcon icon={action?.icon} color={"dark"} clickable={false} />
                                                        </TableRowIcons>
                                                    </PlainContainer>
                                                </TableRowItem>
                                                <TableRowItem size={2} isCenter={false} hasPreview={false} lable={action?.created_by?.first_name} />
                                                <TableRowItem size={2} isCenter={false} hasChildren={true}>
                                                    <TableRowActions>
                                                        <TableButton icon={icons.pencil} iconPosition={"left"} hasIcon={true} bg={"dashboard"} action={() => handleEditSubHeaderAction(action)}>edit</TableButton>
                                                        <TableButton icon={icons.removePlain} iconPosition={"left"} hasIcon={true} bg={"warning"} action={() => handleDeleteSubHeaderAction(action.id)}>delete</TableButton>
                                                    </TableRowActions>
                                                </TableRowItem>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableRows>
                        </Table>
                    </PlainContainer>
                </Card>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Parent Navigation"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowAddParentNavigationItemPopUp(true)} isBottomAction={false}>
                        <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                            <Table>
                                <TableHeading>
                                    <TableHeadingItem size={2} lable={"lable"} />
                                    <TableHeadingItem size={1} lable={"go to type"} />
                                    <TableHeadingItem size={2} lable={"go to"} />
                                    <TableHeadingItem size={1} lable={"sub items"} />
                                    <TableHeadingItem size={1} lable={"created by"} />
                                    <TableHeadingItem size={2} lable={"Actions"} />
                                </TableHeading>

                                <SortableParentNavigationContainer onSortEnd={handleSortParentNavigationEnd} useDragHandle>
                                    {parentNavigationItems.map((item, index) => (
                                        <SortableParentNavigationItem key={`item-${index}`} index={index} value={item} />
                                    ))}
                                </SortableParentNavigationContainer>
                            </Table>
                        </PlainContainer>
                    </Card>
                </PlainContainer>

                <PlainContainer styles={{marginTop: "25px"}}>
                    <Card hasHeading={true} heading={"Header Action"} headingActionIcon={icons.addSolidCircle} headingAction={() => setShowUpdateHeaderActionPopUp(true)} isBottomAction={false}>
                        
                        {
                            headerAction === null
                            ?
                            <>
                                <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                    <NoDataContainer
                                        icon={icons.sad}
                                        heading={"No header actions found!"}
                                        description={"Update header actions by clicking on the below button."}
                                        hasSectionAction={false}
                                        sectionAction={() => alert("section add")}
                                        hasActions={true}
                                        hasPrimaryAction={true}
                                        primaryActionLable={"update"}
                                        primaryAction={() => setShowUpdateHeaderActionPopUp(true)}
                                        hasSecondaryAction={true}
                                        secondaryActionLable={"refresh"}
                                        secondaryAction={() => console.log("refresh")}
                                    />
                                </PlainContainer>
                            </>
                            :
                            <PlainContainer type='full' styles={{paddingTop: "10px", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "25px"}}>
                                <DetailsRow>
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"lable"} 
                                        description={defaults.capitalize(headerAction?.lable)} 
                                    />
                                    <DetailsItem 
                                        hasIcon={false} 
                                        icon={``} 
                                        lable={"value"} 
                                        description={defaults.capitalize(headerAction?.value)} 
                                    />
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsItemWithPreview 
                                        lable={`Image`}
                                        preview={`${globals.API_BASE_URL}${headerAction?.image?.image_url?.full?.high_res}`}
                                        previewType={"image"}
                                        fileName={headerAction?.image?.name?.original}
                                        fileExtension={headerAction?.image?.extension?.original}
                                        fileSize={headerAction?.image?.size?.current}
                                    />
                                </DetailsRow>
                            </PlainContainer>
                        }
                    </Card>
                </PlainContainer>
            </PlainContainer>

            {
                showAddSubHeaderActionPopUp === true
                ?
                <PopUp width="max" heading="Create Sub Header Action" closePopUp={() => handleConcludeAddLeftActionPopUp({status: false})}>
                    <AddSubHeaderActionPopUp user={user} concludeAddActionPopUp={(e) => handleConcludeAddLeftActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showAddParentNavigationItemPopUp === true
                ?
                <PopUp width="max" heading="Create Parent Navigation Item" closePopUp={() => handleConcludeAddParentNavigationItemPopUp({status: false})}>
                    <AddParentNavigationItemPopUp user={user} concludeAddParentNavigationItemPopUp={(e) => handleConcludeAddParentNavigationItemPopUp(e)} />
                </PopUp>
                :
                <></>
            }

            {
                showUpdateHeaderActionPopUp === true
                ?
                <PopUp width="max" heading="Update Header Action" closePopUp={() => handleConcludeUpdateHeaderActionPopUp({status: false})}>
                    <UpdateHeaderActionPopUp user={user} header_action={headerAction} concludeUpdateHeaderActionPopUp={(e) => handleConcludeUpdateHeaderActionPopUp(e)} />
                </PopUp>
                :
                <></>
            }
        </>
    )
}

export default HeaderPage